import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Router, NavigationStart, RoutesRecognized, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DbService } from '../db.service';
import { TuilderUtilities } from '../tuilder-utilities.service';
import { RelativeTimePipe } from '../relative-time.pipe';

@Component({
  selector: 'choose-custom-date-dialog',
  templateUrl: './choose-custom-date-dialog.html',
})

export class ChooseCustomDateDialog {

  form: FormGroup;
  startDate: any;
  endDate: any;

  constructor(
    public dialogRef: MatDialogRef<ChooseCustomDateDialog>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.form = this.fb.group({
      start: [null, Validators.required],
      end: [null, Validators.required],
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-main-frame',
  templateUrl: './main-frame.component.html',
  styleUrls: ['./main-frame.component.css']
})

export class MainFrameComponent implements OnInit {

  title = 'YR Doors';
  isLeader = 0;
  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);
  remindersDue = 0;
  remindersClass = 'badge-normal';
  remindersColor = 'warn';

  constructor(
    private breakpointObserver: BreakpointObserver,
    public db: DbService,
    private router: Router,
    private utils: TuilderUtilities,
    private timePipe: RelativeTimePipe,
    public dialog: MatDialog
  ) {

    router.events.subscribe((val) => {

      if (val instanceof NavigationStart) {
        console.log('NavigationStart', val);
      } else if (val instanceof RoutesRecognized) {
        console.log('RoutesRecognized', val);
      } else if (val instanceof RouteConfigLoadStart) {
        console.log('RouteConfigLoadStart', val);
      } else if (val instanceof RouteConfigLoadEnd) {
        console.log('RouteConfigLoadEnd', val);
      } else if (val instanceof NavigationEnd) {
        console.log('NavigationEnd', val);
      } else if (val instanceof NavigationCancel) {
        console.log('NavigationCancel', val);
      } else if (val instanceof NavigationError) {
        console.log('NavigationError', val);
      }

    });

  }

  canvassers: Array<Object> = [];
  programs: Array<Object> = [];
  programID: Number;

  changeProgram() {
    this.db.changeProgram(this.programID);
    this.update();
  }

  update() {

    localStorage.period = this.db.period;

    if (this.db.period === 'c') {

      this.openDialog();

    } else {

      this.db.update();

    }

  }

  logout() {
    this.db.q('magabooksApp:mbLogout').then(() => {
      this.router.navigateByUrl('login');
    });
  }

  openDialog(): void {

    const dialogRef = this.dialog.open(ChooseCustomDateDialog, {
      width: '600px',
      position: {
        top: '3%'
      },
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result && result.start && result.end) {

        result.end.setHours(23, 59, 59, 999);

        this.db.lastPeriod = 'c';

        const customPeriod = this.utils.find(this.db.periods, 'value', 'c');

        const startMonth = this.utils.getMonthShortName(result.start.getMonth()),
          endMonth = this.utils.getMonthShortName(result.end.getMonth());

        let label;

        if (startMonth != endMonth) {

          label = [startMonth, result.start.getDate(), '-', endMonth, result.end.getDate()].join(' ');

        } else {

          label = [startMonth, result.start.getDate(), '-', result.end.getDate()].join(' ');

        }

        customPeriod.label = label;

        this.db.q('magabooksApp:loadCanvassingStats', {
          period: 'c', start: result.start.getTime(), end: result.end.getTime()
        }).then((data) => {
          this.db.updateData(data['data']);
        });

      } else {

        this.db.period = this.db.lastPeriod;

      }

    });

  }

  ngOnInit() {

    this.db.getLeDetails().then(() => {

      this.isLeader = this.db.le.is_leader;
      this.programs = this.db.programs;
      this.programID = this.db.program.kp_programID;

      this.db.numReminders.subscribe(reminders => {

        let overdue = 0;
        let close = 0;

        for (const followupObj of reminders) {
          
          const now = (new Date()).getTime();
          const oneDay = 86400 * 1000;
          const time = followupObj['time'];
          const relativeStr = this.timePipe.transform(time);

          if (now > time) {
            overdue++;
            // this.setColor(this.colors.overdue);
          } else if ((time - now) < oneDay || relativeStr.match('Tomorrow')) {
            close++;
            // this.setColor(this.colors.close);
          }

        };

        if (overdue) {
          this.remindersClass = 'badge-overdue';
          this.remindersDue = overdue;
        } else if (close) {
          this.remindersClass = 'badge-close';
          this.remindersDue = close;
        } else {
          this.remindersClass = 'badge-normal';
          this.remindersDue = reminders.length;
        }

        // remindersClass

      });

      this.db.data.subscribe(data => {

        if (!data) {
          return;
        }

        const canvassersObj = {},
          canvassers = [];

        for (const row of data['canvassers']) {    
          if (!canvassersObj[row['kf_personID']]) {
            canvassersObj[row['kf_personID']] = row;
            canvassers.push(row);
          }
        };

        canvassers.sort((a, b) => {
          return a.name.localeCompare(b.name);
        })

        this.canvassers = [{ 'name': 'All ' + canvassers.length + ' Canvassers', 'kf_personID': 0 }].concat(canvassers);

      });

      this.db.update();

    });

  }

}
