import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';

import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';

import { MyDashboardComponent, downloadFileDialog } from './my-dashboard/my-dashboard.component';
import { MyTableComponent } from './my-table/my-table.component';
import { AppRoutingModule } from './app-routing.module';

import { NotificationsComponent } from './notifications/notifications.component';
import { ImportMaterialModules } from './material.import';

import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { DbService } from './db.service';
import { NotificationService } from './notifications.service';
import { TuilderUtilities } from './tuilder-utilities.service';
import { LoginComponent, PinPadBottomSheet } from './login/login.component';
import { MainFrameComponent, ChooseCustomDateDialog } from './main-frame/main-frame.component';
import { SecretPasscodeDialog } from './login/login.component';
import { HttpIndicatorComponent } from './http-indicator/http-indicator.component';

import { ChartsModule } from 'ng2-charts';
import { EntryComponent } from './entry/entry.component';
import { BookFeedComponent, ChooseLeaderComponent, CreateContactComponent } from './book-feed/book-feed.component';
import { EntryListComponent } from './entry-list/entry-list.component';
import { MapComponent } from './map/map.component';
import { FabMoneyEntryDirective, moneyDialog } from './fab-money-entry/fab-money-entry.component';
import { MoneySumPipe } from './money-sum.pipe';
import { LeaderFeedComponent } from './leader-feed/leader-feed.component';
import { TimeAgoPipe } from './time-ago.pipe';
import { SettingsComponent } from './settings/settings.component';
import { MyContactsComponent, ConfirmAddressComponent } from './my-contacts/my-contacts.component';
import { ViewContactsComponent } from './view-contacts/view-contacts.component';
import { PhoneFormatPipe } from './phone-format.pipe';
import { TruncatePipe } from './truncate.pipe';
import { AddVisitComponent } from './overlays/add-visit/add-visit.component';
import { RelativeTimePipe } from './relative-time.pipe';
import { SortPipe } from './sort.pipe';
import { FollowupColorDirective } from './followup-color.directive';
import { YesNoDialogueComponent } from './yes-no-dialogue/yes-no-dialogue.component';
import { ShareContactComponent } from './overlays/share-contact/share-contact.component';
import { SimpleEventComponent } from './simple-event/simple-event.component';
import { SimpleEventHomeComponent } from './simple-event-home/simple-event-home.component';
import { SimpleEventMapComponent } from './simple-event-map/simple-event-map.component';
import { SimpleEventLoginComponent } from './simple-event-login/simple-event-login.component';
import { SimpleEventContactsComponent } from './simple-event-contacts/simple-event-contacts.component';

enableProdMode();

@NgModule({
  declarations: [
    AppComponent,
    MyDashboardComponent,
    downloadFileDialog,
    ChooseLeaderComponent,
    ConfirmAddressComponent,
    CreateContactComponent,
    MyTableComponent,
    PinPadBottomSheet,
    NotificationsComponent,
    LoginComponent,
    MainFrameComponent,
    ChooseCustomDateDialog,
    SecretPasscodeDialog,
    moneyDialog,
    HttpIndicatorComponent,
    EntryComponent,
    BookFeedComponent,
    EntryListComponent,
    MapComponent,
    FabMoneyEntryDirective,
    MoneySumPipe,
    LeaderFeedComponent,
    TimeAgoPipe,
    SettingsComponent,
    MyContactsComponent,
    ViewContactsComponent,
    PhoneFormatPipe,
    TruncatePipe,
    AddVisitComponent,
    RelativeTimePipe,
    SortPipe,
    FollowupColorDirective,
    YesNoDialogueComponent,
    ShareContactComponent,
    SimpleEventComponent,
    SimpleEventHomeComponent,
    SimpleEventMapComponent,
    SimpleEventLoginComponent,
    SimpleEventContactsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ChartsModule,
    LayoutModule,
    OverlayModule,
    AppRoutingModule,
    ImportMaterialModules,
  ],
  providers: [
    DbService,
    NotificationService,
    MapComponent,
    TuilderUtilities,
    HttpIndicatorComponent,
    DatePipe,
    RelativeTimePipe,
    SortPipe,
    TimeAgoPipe
  ],
  exports: [
    TimeAgoPipe,
    FabMoneyEntryDirective
  ],
  entryComponents: [
    ChooseCustomDateDialog,
    SecretPasscodeDialog,
    downloadFileDialog,
    PinPadBottomSheet,
    ChooseLeaderComponent,
    ConfirmAddressComponent,
    CreateContactComponent,
    AddVisitComponent,
    YesNoDialogueComponent,
    ShareContactComponent,
    moneyDialog
  ],
  bootstrap: [AppComponent]
})
export class AppModule {



}
