import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { TuilderUtilities } from '../../tuilder-utilities.service';
import { DbService } from '../../db.service';
import { MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../date.adapter';

@Component({
  selector: 'app-add-visit',
  templateUrl: './add-visit.component.html',
  styleUrls: ['./add-visit.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class AddVisitComponent implements OnInit, OnDestroy {

  constructor(
    public dialogRef: MatDialogRef<AddVisitComponent>,
    private db: DbService,
    private utils: TuilderUtilities,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  subs = [];
  canvassers = [];
  type = this.data['what'];
  title = { visit: 'Visit', study: 'Bible Study', followup: 'Followup Reminder' }[this.data['what']];
  hasStudy = { visit: false, study: true, followup: false }[this.data['what']];
  hasVisit = { visit: true, study: false, followup: false }[this.data['what']];
  VisitOrBibleStudy = this.hasStudy ? 'b' : 'v';
  followUp = { visit: false, study: false, followup: true }[this.data['what']];
  followUps = [];
  edit = this.data['editRow'] ? true : false;
  visit = {
    kp_visitID: 'new',
    canvasser: null,
    date: null,
    timepicker: null,
    time: null,
    kf_canvasserID: this.db['le'].kp_canvasserID,
    notes: '',
    bible_study: this.data['what'] === 'study' ? 1 : 0,
    study_type: null,
    guide_type: null,
    home: true
  };
  contact = this.data['contact'];
  followUpTime = {
    kp_followupID: 'new',
    date: null,
    timepicker: '09:00',
    time: null,
    title: '',
    repeat_frequency: 0,
    kf_canvasserID: this.db['le'].kp_canvasserID,
    kf_personID_le: null
  };
  repeatOptions = [
    { value: 0, label: 'Don\'t Repeat' },
    { value: '1w', label: 'Weekly' },
    { value: '2w', label: 'Fortnightly' },
    { value: '1m', label: 'Monthly' },
    { value: '1y', label: 'Yearly' }
  ];
  completeFollowup = {};

  onNoClick(): void {
    this.dialogRef.close();
  }

  setVisit() {
    if (this.VisitOrBibleStudy === 'v') {
      this.data['what'] = 'visit';
      this.hasStudy = false;
      this.hasVisit = true;
      this.visit.bible_study = 0;
    } else if (this.VisitOrBibleStudy === 'b') {
      this.data['what'] = 'study';
      this.hasStudy = true;
      this.hasVisit = false;
      this.visit.bible_study = 1;
      this.visit.home = true;
    }
  }

  addReminder() {
    this.followUp = true;
    this.followUpTime.date = new Date(this.visit.date.getTime());
    this.followUpTime.date.setDate(this.followUpTime.date.getDate() + 7);
    this.followUpTime.timepicker = this.visit.timepicker;
  }

  save() {

    if (this.hasVisit || this.hasStudy) {

      this.utils.parseDateTime(this.visit);
      this.visit.canvasser = this.utils.find(this.db.dataObservable.value['canvassers'], 'kp_canvasserID', this.visit.kf_canvasserID);

      this.visit.time = Math.round(this.visit.time / 1000);

      if (!this.data['editRow']) {

        this.contact.visits = this.contact.visits || [];
        this.contact.visits.unshift(this.visit);

      }

    }

    if (this.followUp) {

      this.utils.parseDateTime(this.followUpTime);

      if (!this.data['editRow'] || this.data['what'] !== 'followup') {

        // this.followUpTime

        const canvasser = this.utils.find(this.canvassers, 'kp_canvasserID', this.followUpTime.kf_canvasserID);

        if (canvasser) {
          this.followUpTime.kf_personID_le = canvasser.kf_personID;
        }

        this.contact.followup = this.contact.followup || [];
        this.contact.followup.push(this.followUpTime);

      }

    }

    const output = { completeFollowup: [] };

    for (const [key, value] of Object.entries(this.completeFollowup)) {
      if (value) {
        output.completeFollowup.push(this.followUps[key]);
      }
    }

    this.dialogRef.close(output);

  }

  close() {

    this.dialogRef.close();

  }

  timeStrFromDate(dateObj) {

    return this.utils.timeStrFromDate(dateObj);

  }

  ngOnInit() {

    this.visit.date = new Date();

    this.visit.timepicker = this.timeStrFromDate(new Date());

    if (this.data['editRow']) {
      switch (this.data['what']) {
        case 'followup':

          this.followUpTime = this.data['editRow'];
          this.followUpTime.date = new Date(this.followUpTime.time);
          this.followUpTime.timepicker = this.timeStrFromDate(this.followUpTime.date);
          this.title = 'Edit Followup Reminder';

          break;
        case 'visit':
        case 'study':

          this.visit = this.data['editRow'];
          this.visit.date = new Date(this.visit.time * 1000);
          this.visit.timepicker = this.timeStrFromDate(this.visit.date);
          this.title = 'Edit ' + (this.data['what'] === 'study' ? 'Study' : 'Visit');

          break;
      }
    }

    if (this.hasVisit || this.hasStudy) {

      this.followUps = this.data['contact']['followup'];

    }

    // this.data

    this.subs.push(this.db.data.subscribe(data => {

      if (!data) {
        return;
      }

      const canvassersObj = {},
        canvassers = [];

      for (const row of data['canvassers']) {
        if (!canvassersObj[row['kf_personID']]) {
          canvassersObj[row['kf_personID']] = row;
          canvassers.push(row);
        }
      }

      canvassers.sort((a, b) => a.name.localeCompare(b.name));
      this.canvassers = canvassers;

    }));

  }

  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

}
