import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../notifications.service';
import { trigger, state, animate, query, transition, style, stagger } from '@angular/animations';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0', transform: 'scale(0.4)' }),
        animate('.3s ease-out', style({ opacity: '1', transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: '1', transform: 'scale(1)' }),
        animate('.3s ease-out', style({ opacity: '0', transform: 'scale(0.4)' })),
      ]),
    ]),
  ],
})
export class NotificationsComponent implements OnInit {

  constructor(public notificationsService: NotificationService) {}

  ngOnInit() {
  }

}
