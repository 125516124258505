import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }

    let reverse;

    if (field.charAt(0) === '-') {

      reverse = true;
      field = field.substr(1);

    } else {

      reverse = false;

    }

    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });

    if (reverse) {
      array.reverse();
    }

    return array;
  }
}