import { Component, OnInit, HostListener, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DbService } from '../db.service';
import { Router } from '@angular/router';
import { TuilderUtilities } from '../tuilder-utilities.service';
import { NotificationService, Notification } from '../notifications.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material';

@Component({
  selector: 'secret-passcode-dialog',
  templateUrl: './secret-passcode-dialog.html',
})

export class SecretPasscodeDialog implements OnInit {

  form: FormGroup;
  startDate: any;
  endDate: any;

  constructor(
    public dialogRef: MatDialogRef<SecretPasscodeDialog>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.form = this.fb.group({
      password: '',
    });
  }

  login() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

  skip() {
    this.dialogRef.close({ password: false });
  }

}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: FormGroup;

  constructor(
    private db: DbService,
    private router: Router,
    private notifications: NotificationService,
    private formBuilder: FormBuilder,
    private utils: TuilderUtilities,
    private bottomSheet: MatBottomSheet
  ) { }

  loginObj = {};

  openBottomSheet(): void {
    this.bottomSheet.open(PinPadBottomSheet);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      remember: [null],
    });
    this.openBottomSheet();
  }

  login() {

    this.db.q('login', { data: this.utils.getFormData(this.form) }).then((data) => {
      if (data['success'] && data['loggedIn']) {
        this.router.navigateByUrl('');
      }
    });
  }

}

@Component({
  selector: 'pin-pad-bottom-sheet',
  templateUrl: 'pin-pad-bottom-sheet.html',
  styleUrls: ['./login.component.css']
})
export class PinPadBottomSheet {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<PinPadBottomSheet>,
    private db: DbService,
    private router: Router,
    private utils: TuilderUtilities,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef
  ) { }

  pin = '';
  loggingIn = 0;
  pwdOpen = 0;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.pwdOpen && ([1, 2, 3, 4, 5, 6, 7, 8, 9, 0].indexOf(+event.key) != -1)) {
      this.add2Pin(+event.key);
    }
  }

  add2Pin(num: number) {

    this.ref.markForCheck();

    if (this.loggingIn) {
      return;
    }

    this.pin = this.pin + num.toString();

    if (this.pin.length > 3) {

      this.pwdOpen = 1;

      const dialogRef = this.dialog.open(SecretPasscodeDialog, {
        width: '600px',
        position: {
          top: '3%'
        },
        data: {}
      });

      dialogRef.afterClosed().subscribe(result => {

        this.pwdOpen = 0;

        this.loggingIn = 1;
        this.ref.markForCheck();

        this.db.q('magabooksApp:canvasserPinLogin', { pin: this.pin, pwd: result && result.password }).then((data) => {

          if (data['success'] && data['loggedIn']) {
            this.db.le = data['le'];
            this.db.program = data['program'];
            this.db.leaders = data['leaders'];
            this.db.leader = data['leader'];
            this.router.navigateByUrl('entry');
            this.bottomSheetRef.dismiss();
          }

          this.ref.markForCheck();

          this.loggingIn = 0;
          this.pin = '';

        });

      });

      if (this.utils.getCookieValue('signified')) { // Password not required
        console.log('Password not required');
        this.dialog.closeAll();
      }

    }

  }

  deletePin() {
    this.pin = '';
  }

}