import { Component, Directive, OnInit, Input, Inject, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DbService } from '../db.service';
import { TuilderUtilities } from '../tuilder-utilities.service';

@Directive({
  selector: '[appFabMoneyEntry]'
})
export class FabMoneyEntryDirective implements OnInit {

  @Input() appFabMoneyEntry: string;

  @HostListener('click') onClick() {

    this.dialog.open(moneyDialog, {
      width: '500px',
      data: {
        date: this.appFabMoneyEntry,
        money: this.db.money[this.appFabMoneyEntry] ? {...this.db.money[this.appFabMoneyEntry]} : {}
      }
    });

  }

  constructor(
    private db: DbService,
    private utils: TuilderUtilities,
    public dialog: MatDialog
  ) { }

  ngOnInit() {}

}

@Component({
  selector: 'money-entry-dialog',
  templateUrl: './money-entry-dialog.html',
})

export class moneyDialog implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<moneyDialog>,
    private db: DbService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() { }

  save() {
    this.db.money[this.data.date] = this.db.money[this.data.date] || {};
    Object.assign(this.db.money[this.data.date], this.data.money);
    this.db.q('magabooksApp:saveMoney', {
      date: this.data.date,
      money: this.data.money,
      program: this.db.program['kp_programID'],
      le: this.db.le['kp_canvasserID']
    });
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

}
