import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class TuilderUtilities {

  constructor() { }

  scriptCache = {};
  scriptProcessing = {};

  unsubscribe(subs) {
    for (const s of subs) {
      s.unsubscribe();
    }
  }

  extend(destination, source) {
    Object.assign(destination, source);
    return destination;
  }

  timeStrFromDate(dateObj) {

    let Hours = dateObj.getHours();
    Hours = ("0" + Hours).slice(-2);

    let Minutes = dateObj.getMinutes();
    Minutes = ("0" + Minutes).slice(-2);

    const output = Hours + ':' + Minutes;

    return output;

  }

  watch(oObj, sProp) {

    const sPrivateProp = "$_" + sProp + "_$"; // to minimize the name clash risk
    oObj[sPrivateProp] = oObj[sProp];

    // overwrite with accessor
    Object.defineProperty(oObj, sProp, {
      get: function () {
        return oObj[sPrivateProp];
      },

      set: function (value) {
        console.warn(value);
        debugger; // sets breakpoint
        oObj[sPrivateProp] = value;
      }
    });

  }

  parseDateTime(obj) {

    const time = obj.timepicker.split(':');
    if (time.length === 2) {

      let Hour = parseInt(time[0], 10);
      const Minute = parseInt(time[1], 10);

      obj.date.setHours(Hour);
      obj.date.setMinutes(Minute);

    }

    obj.time = obj.date.getTime();
    delete obj.date;

  }

  colorFromString(string) {

    const hashCode = function (str) { // java String#hashCode
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    };

    const intToRGB = function (i) {
      const c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

      return '00000'.substring(0, 6 - c.length) + c;

    };

    return intToRGB(hashCode(string));

  }

  loadScript(url) {

    const promise = new Promise<void>((resolve, reject) => {

      if (this.scriptProcessing[url]) {
        return;
      }

      const head = document.getElementsByTagName('head')[0],
        script = document.createElement('script');

      this.scriptProcessing[url] = 1;

      if (this.scriptCache[url]) {
        resolve();
        this.scriptProcessing[url] = 0;
      } else {
        const complete = () => {
          this.scriptProcessing[url] = 0;
          resolve();
        };
        script.type = 'text/javascript';
        script.src = url;

        script['onreadystatechange'] = complete;
        script.onload = complete;
        this.scriptCache[url] = 1;

        head.appendChild(script);
      }

    });

    return promise;

  }

  setCookie(name: string, value: string, days: number) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  getCookieValue(key: string) {
    const b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  strip(html: string) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  delete(source, row, idx?) {
    if (idx === undefined) {
      idx = source.indexOf(row);
    }
    if (idx > -1) {
      source.splice(idx, 1);
    }
  }

  deleteByVal(source, key, val) {
    if (!source) {
      return;
    }
    for (let i = source.length - 1; i >= 0; i--) {
      if (source[i][key] == val) {
        source.splice(i, 1);
      }
    }
    return source;
  }

  addSum(nums: any) {

    let output = 0;
    for (const n of nums) {
      output += (parseFloat(n || 0) || 0)
    }

    return output;

  }

  getMonthShortName(monthNumber: number) {

    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    return monthNames[monthNumber] || 'Unkown';

  }

  getFormData(form: FormGroup) {

    const data = {};

    Object.keys(form.controls).forEach(key => {

      data[key] = form.get(key).value;

    });

    return data;

  }

  find(haystack, key, needle?) {

    return haystack.find((row) => {
      return row[key] === needle
    })

  }

  filter(haystack, key, needle?) {

    return haystack.filter((row) => {
      return row[key] === needle
    })

  }

  getContactType(contacts) {

    let type = 'nothome';

    if (!contacts) {
      return type;
    }

    let Contact = false;
    let SomeoneNotInterested = false;
    let NobodyHome = false;

    // Contact = kf_personID_contact
    // Not Interested == !kf_personID_contact AND home
    // Not Home = !home

    for (const row of contacts) {

      const home = row.visits.find((VisitRow) => {
        return VisitRow.home;
      }) ? true : false;

      if (row.kf_personID_contact) {
        Contact = true;
      }

      if (home && !row.kf_personID_contact) {
        SomeoneNotInterested = true;
      }

      if (!home) {
        NobodyHome = true;
      }

    };

    if (Contact) {
      type = 'contact';
    } else if (SomeoneNotInterested) {
      type = 'notinterested';
    } else if (!NobodyHome) {
      type = 'nothome';
    }

    return type;

  }

}
