import { TuilderUtilities } from './../tuilder-utilities.service'
import { ActivatedRoute } from '@angular/router'
import { DbService } from 'src/app/db.service'
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-simple-event-home',
  templateUrl: './simple-event-home.component.html',
  styleUrls: ['./simple-event-home.component.css']
})
export class SimpleEventHomeComponent implements OnInit {

  constructor(
    private db: DbService,
    private route: ActivatedRoute,
    private utils: TuilderUtilities
  ) {
    localStorage.path = route.url
  }

  eventID = window.location.pathname.split('/')[2];

  NumberOfCanvassers: number
  NumberOfDoors: number
  NumberOfPeopleHome: number
  NumberOfPeopleNotHome: number
  NumberOfPeopleNotInterested: number
  NumberOfEntries: number
  NumberOfLocationLogs: number
  Entries: Array<Object>
  title: String
  isMenuOpen = false;

  ngOnInit() {

    this.db.loadEvent(this.eventID).then((data) => {

      this.title = data.program.name

      if (data.eventLogin) {

        this.NumberOfCanvassers = data['canvassers'].length
        this.NumberOfDoors = data['contacts'].length

        const contacts = data['contacts'].reduce((acc, item) => {
          (acc[item['kf_locationID']] = acc[item['kf_locationID']] || []).push(item)
          return acc
        }, {})

        this.NumberOfPeopleHome = 0
        this.NumberOfPeopleNotHome = 0
        this.NumberOfPeopleNotInterested = 0

        for (const contact of Object.entries(contacts)) {
          const type = this.utils.getContactType(contact)
          if (type === 'nothome') {
            this.NumberOfPeopleNotHome++
          } else if (type === 'contact') {
            this.NumberOfPeopleHome++
          } else if (type === 'notinterested') {
            this.NumberOfPeopleNotInterested++
          }
        }

        this.NumberOfEntries = data['entries'].length
        this.NumberOfLocationLogs = 0

        if (data['locationPoints'].length === 2) {
          for (const points of data['locationPoints'][1]) {
            this.NumberOfLocationLogs += points.length
          }
        } else {
          for (const points of data['locationPoints']) {
            this.NumberOfLocationLogs += points.length
          }
        }

        console.log(data['locationPoints'])

        this.Entries = []

        const entries = data['entries'].reduce((acc, item) => {
          (acc[item['code']] = acc[item['code']] || []).push(item)
          return acc
        }, {})

        for (const [bookCode, rows] of Object.entries(entries) as any) {

          const image = data['entryImages'][bookCode]

          const imageUrl = image && image.root + image.path + 'x500/' + image.name

          this.Entries.push({
            name: rows[0].bkName,
            code: bookCode,
            number: rows.length,
            image: imageUrl
          })

        }

        this.Entries.sort((a: any, b: any) => a.number - b.number).reverse()

      }
    })

  }

}
