import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../notifications.service';

@Component({
  selector: 'app-http-indicator',
  templateUrl: './http-indicator.component.html',
  styleUrls: ['./http-indicator.component.css']
})
export class HttpIndicatorComponent implements OnInit {

  constructor(public notificationsService: NotificationService) {}

  ngOnInit() {
  }

}
