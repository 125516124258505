import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbService } from '../db.service';
import { TuilderUtilities } from '../tuilder-utilities.service';

@Component({
  selector: 'app-entry-list',
  templateUrl: './entry-list.component.html',
  styleUrls: ['./entry-list.component.css']
})
export class EntryListComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    public db: DbService,
    private utils: TuilderUtilities
  ) { }

  time: number;
  private sub = [];
  group: string;
  money;
  topsheet = {};
  entries = [];
  delete;

  ngOnInit() {

    this.sub.push(this.route.params.subscribe(params => {
      this.time = +params['time'];
      this.group = this.db.getTimeGroup(this.time);
      this.topsheet = this.utils.find(this.db.groupEntries(), 'label', this.group);

      this.money = this.db.money[this.time];

    }));

    this.sub.push(this.db.myEntries.subscribe(entries => {

      this.topsheet = this.utils.find(this.db.groupEntries(), 'label', this.group);

    }));

    this.delete = function (row) {
      if (confirm('Remove entry ' + row.obj.name)) {
        this.utils.delete(this.topsheet.entries, row);
        this.db.removeEntries(row);
        this.db.q('magabooksApp:removeEntry', { entry: row });
        this.topsheet = this.utils.find(this.db.groupEntries(), 'label', this.group);
      }
    };

  }

  ngOnDestroy() {
    for (const sub of this.sub) {
      sub.unsubscribe();
    }
  }

}
