import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { MyTableDataSource } from './my-table-datasource';
import { DbService } from '../db.service';
import { TuilderUtilities } from '../tuilder-utilities.service';

@Component({
  selector: 'my-table',
  templateUrl: './my-table.component.html',
  styleUrls: ['./my-table.component.css']
})
export class MyTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MyTableDataSource;

  constructor(
    private db: DbService,
    private utils: TuilderUtilities
  ) { };

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  // displayedColumns = ['id', 'name'];

  // displayedColumns = ['name', 'gross', 'net', 'books', 'spiritual', 'days', 'books / day', 'donations / day'];
  displayedColumns = [
    'name',
    'gross',
    'net',
    'magabooks',
    'spiritual',
    'dropdown',
    'dvdOther',
    'days',
    'booksPerDay',
    'donationsPerDay'
  ];

  ngOnInit() {

    this.dataSource = new MyTableDataSource(this.paginator, this.sort, this.db, this.utils);

  }
}
