import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: any, compact?: any): any {

    if (!value || value === 0 || value === '0') {
      return '';
    } else {
      value = parseInt(value, 10);
    }

    if (('' + value).length >= 13) {
      value = value / 1000;
    }

    const lengths = [60, 60, 24, 7, 4.35, 12, 10],
      now = (new Date()).getTime() / 1000;

    let periods = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year', 'decade'];

    if (compact) {
      periods = ['sec', 'min', 'hr', 'd', 'wk', 'm', 'yr', 'dec'];
    }

    let
      difference = now - value,
      tense, pre, j;

    if (difference > 0) {
      tense = 'ago';
      pre = '';
    } else {
      tense = '';
      pre = 'in ';
      difference = value - now;
    }

    for (j = 0; difference >= lengths[j] && j < lengths.length - 1; j++) {
      difference /= lengths[j];
    }

    difference = Math.round(difference);

    if (difference !== 1 && !compact) {
      periods[j] += 's';
    }

    return pre + ' ' + difference + ' ' + periods[j] + ' ' + tense;

  }

}
