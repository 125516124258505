import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationService, Notification } from './notifications.service';
import { TuilderUtilities } from './tuilder-utilities.service';
import { HttpIndicatorComponent } from './http-indicator/http-indicator.component';
import * as moment from 'moment-timezone';

@Injectable()
export class DbService {

  // URL to web api
  endpoint;

  mapsApiKey = 'AIzaSyDF9ICs220WRHhEpm3vtkPfq6Rp-rEL74g';
  period = localStorage.period || 'event';
  money;
  manageSession = true;
  canvasserModel = 0;
  program;
  programs = [];
  leaders;
  leader;
  le;
  myEntriesGroups = {};
  inventory = [];
  mapCentre;
  mapZoom;
  tz;
  loaded;
  mapLayers;

  periods = [
    { value: 'd30', label: 'Last 30 days' },
    { value: 'd7', label: 'Last 7 days' },
    { value: 'd0', label: 'Today' },
    { value: 'd-1', label: 'Yesterday' },
    { value: 'm', label: 'This month' },
    { value: 'm-1', label: 'Last month' },
    { value: 'd60', label: 'Last 60 days' },
    { value: 'd90', label: 'Last 90 days' },
    { value: 'd180', label: 'Last 180 days' },
    { value: 'y', label: 'This Year' },
    { value: 'y-1', label: 'Last Year' },
    { value: 'y2', label: 'Last 2 Years' },
    { value: 'l', label: 'Lifetime' },
    { value: 'c', label: 'Custom' }
  ];

  lastPeriod;

  loggedIn = 0;

  constructor(
    private http: HttpClient,
    private router: Router,
    private utils: TuilderUtilities,
    private notify: NotificationService,
    private httpIndicator: HttpIndicatorComponent
  ) {

    if (window.location.hostname === 'localhost' || window.location.hostname.match(/192\.168\.1\./)) {
      this.endpoint = 'http://' + window.location.hostname + '/tuilder.com/~do';
    } else {
      this.endpoint = 'https://doors.nnsw.youthrush.au/~do';
    }

    this.mapLayers = (localStorage.layers && JSON.parse(localStorage.layers)) || [1, 1, 0, 1, 1];

    const WhitelistURLs = ['SimpleEvent', 'map', 'entry'];

    if (localStorage.path && !WhitelistURLs.includes(window.location.pathname.split('/')[1])) {
      
      this.router.navigateByUrl(localStorage.path);

    }

  }

  dataObservable = new BehaviorSubject<object>({
    canvassers: [],
    inventory: [],
    donations: [],
    expenses: [],
    sales: [],
    topsheets: [],
  });
  private canvasserObservable = new BehaviorSubject<number>(0);
  private entriesObservable = new BehaviorSubject([]);
  remindersObservable = new BehaviorSubject([]);

  data = this.dataObservable.asObservable();
  canvasser = this.canvasserObservable.asObservable();
  myEntries = this.entriesObservable.asObservable();
  numReminders = this.remindersObservable.asObservable();
  loginFailureCount = 0;
  leDetailsLoaded = null;
  eventLoaded = null;

  getdata() {
    return this.dataObservable.value;
  }

  clearEntries() {
    this.entriesObservable.next([]);
  }

  addEntries(data: object) {
    this.entriesObservable.next(this.entriesObservable.value.concat(data));
  }

  removeEntries(entry: object) {
    this.entriesObservable.next(
      this.utils.deleteByVal(this.entriesObservable.value.concat(entry), 'kp_itemID', entry['kp_itemID'])
    );
  }

  updateData(data: object) {
    this.dataObservable.next(data);
  }

  updateCanvasser() {
    this.canvasserObservable.next(this.canvasserModel);
  }

  changeProgram(id: Number) {

    this.program = this.utils.find(this.programs, 'kp_programID', id);
    this.le.kf_programID = id;
    this.utils.setCookie('le_program', id.toString(), 365);
    this.leDetailsLoaded = false;
    this.getLeDetails();

    return this.program;

  }

  groupEntries() {

    const groups: Object = {},
      entries = this.entriesObservable.value.sort((a, b) => {
        return a.date - b.date
      }).reverse();

    entries.forEach((row) => {

      const groupName = this.getTimeGroup(row['time']);

      const startOfDay = moment(parseInt(row['time'], 10)).startOf('day');

      if (typeof groups[groupName] === 'undefined') {

        groups[groupName] = {
          label: groupName,
          magabooks: 0,
          dropdowns: 0,
          other: 0,
          time: startOfDay.valueOf(),
          total: 0,
          entries: []
        };

      }

      row.obj = this.utils.find(this.inventory, 'code', row['code']);

      groups[groupName].entries.push(row);

    });

    for (const [name, obj] of Object.entries(groups)) {

      for (const entry of obj['entries']) {
        const inventoryItem = this.utils.find(this.inventory, 'code', entry['code']);
        if (inventoryItem) {

          obj['total'] += entry['qty'];

          obj[{ Magabook: 'magabooks', Dropdown: 'dropdowns' }[inventoryItem.type] || 'other'] += entry['qty'];

        }
      };

    };

    return Object.values(groups)

  }

  getTimeGroup(time) {

    const start = moment().startOf('day');

    const end = moment().endOf('day').valueOf();

    if (time >= start.valueOf() && time <= end) {
      return 'Today';
    }

    const yesterdayStart = start.subtract(1, 'days').valueOf();

    if (time >= yesterdayStart && time < start.valueOf()) {
      return 'Yesterday';
    } else {
      return moment(parseFloat(time)).format('MMM D');
    }

  }


  update() {

    if (this.lastPeriod === 'c') {

      this.utils.find(this.periods, 'value', 'c').label = 'Custom';

    }

    this.lastPeriod = this.period;

    const promise = this.q('magabooksApp:loadCanvassingStats', { period: this.period, program: this.program.kp_programID });
    this.loaded = promise;

    promise.then((data) => {

      this.updateData(data['data']);

      if (data['mapCentre']) {
        this.mapCentre = data['mapCentre'];
        this.mapZoom = data['mapZoom'];
      }

    });

    return promise;

  }

  getJson(url) {

    return this.http.get(url);

  }

  updateReminders() {

    this.q('magabooksApp:getReminders', { le: this.le.kf_personID }).then(data => {
      this.remindersObservable.next(data['reminders']);
    });
  }

  loadEvent(eventID) {

    if (!this.eventLoaded) {

      this.eventLoaded = this.q('magabooksApp:loadEvent', { event: eventID });

    }

    this.eventLoaded.then((res) => {
      if (!res.eventLogin) {
        this.router.navigateByUrl('/SimpleEvent/' + eventID + '/login');
      }
    });

    return this.eventLoaded;

  }

  getLeDetails() {

    if (!this.leDetailsLoaded) {

      this.leDetailsLoaded = this.q('magabooksApp:getLeDetails', { program: this.utils.getCookieValue('le_program') }).then((data) => {

        if (!data['loggedIn']) {

          this.leDetailsLoaded = false;
          this.router.navigateByUrl('/login');

        } else {

          this.le = data['le'];
          this.le = this.le;
          this.programs = data['programs'];
          this.program = this.utils.find(this.programs, 'kp_programID', data['program']);
          this.leaders = data['leaders'];
          this.leader = data['leader'];
          this.leaders = data['leaders'];
          this.leader = data['leader'];

        }
      });

    }

    return this.leDetailsLoaded;

  }

  isLeader() {

    return (this.le || {}).is_leader ? true : false;

  }

  q(cmd: string, params?: object) {

    if (this.loginFailureCount > 5) {
      const promise2 = new Promise((resolve, reject) => {
        this.loginFailureCount = 0;
        resolve(false);
        this.router.navigateByUrl('/login');
      });
      return promise2;
    }

    params = params || {};

    if (cmd.indexOf(':') !== -1) {
      const str = cmd.split(':');
      params['prefix'] = str[0];
      params['cmd'] = str[1];
    } else {
      params['cmd'] = cmd;
    }

    params['localAnglarServe'] = 1;

    const promise = new Promise((resolve, reject) => {

      this.notify.numRequests++;

      this.http.post(this.endpoint, params, { responseType: 'text' }).subscribe(data => {

        this.notify.numRequests--;

        data = this.utils.isJson(data) && JSON.parse(data) || data;

        if (data && data['tz']) {
          this.tz = data['tz'];
          moment.tz.setDefault(data['tz']['utc']);
        }

        const DataIsString = typeof data === 'string'

        this.loggedIn = data['loggedIn'];

        if (DataIsString || !data['success']) {

          if (data) {

            const notification = new Notification();
            notification.message = DataIsString ? this.utils.strip(data) : data['message'] || data['notify'];
            notification.type = 'error';

            console.warn(notification.message);

            this.notify.add(notification);

          }

        } else {

          data['notification'] = new Notification();
          data['notification'].message = data['message'] || data['notify'];
          data['notification'].type = data['type'] || (data['success'] ? 'success' : 'error');

          if (data['notify']) {
            this.notify.add(data['notification']);
          }

        }

        if (!this.loggedIn && this.manageSession) {

          console.log(this.utils.getCookieValue('tam'), this.utils.getCookieValue('signified'));

          if (
            this.utils.getCookieValue('tam') && // lvl1
            this.utils.getCookieValue('signified') // lvl2
          ) { // Full auto-login possible

            this.loginFailureCount++;

            this.q('magabooksApp:autologin').then((response) => {
              if (response) {

                this.loginFailureCount = 0;

                this.q(cmd, params).then((newdata) => {

                  resolve(newdata);

                });
              }
            });

          } else if (
            this.utils.getCookieValue('signified') &&
            !this.utils.getCookieValue('tam')
          ) { // Pin login required

            // resolve(data);
            resolve(false);
            this.router.navigateByUrl('/login');

          } else { // Full login required

            // resolve(data);
            resolve(false);
            this.router.navigateByUrl('/login');

          }

        } else {

          resolve(data);

        }


      });

    });

    return promise;

  }

}
