import { ConfirmAddressComponent } from './../my-contacts/my-contacts.component';
import { ShareContactComponent } from './../overlays/share-contact/share-contact.component';
import { Component, OnInit, OnDestroy, ViewChild, Injector } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { TuilderUtilities } from '../tuilder-utilities.service';
import { DbService } from '../db.service';
import { AddVisitComponent } from '../overlays/add-visit/add-visit.component';
import { YesNoDialogueComponent } from '../yes-no-dialogue/yes-no-dialogue.component';
import { SortPipe } from '../sort.pipe';

@Component({
  selector: 'app-view-contacts',
  templateUrl: './view-contacts.component.html',
  styleUrls: ['./view-contacts.component.css']
})
export class ViewContactsComponent implements OnInit, OnDestroy {

  @ViewChild('contactBar') contactBar;

  data;
  dialogRef;

  constructor(
    private utils: TuilderUtilities,
    private db: DbService,
    private currentRoute: ActivatedRoute,
    public dialog: MatDialog,
    private sort: SortPipe,
    private router: Router,
    private injector: Injector
  ) {
    this.data = this.injector.get(MAT_DIALOG_DATA, null);

    if (this.data) {
      window.setTimeout(() => {
        this.dialogRef = this.dialog.getDialogById(this.data.id);
      });
    }

    console.log('Constructor View Contacts');

  }

  private sub = [];

  surveyQuestionCtrl = new FormControl();

  placeID: string;
  contactID: number;
  point;
  saveOnClose = true;
  contacts = [];
  current = 0;
  questionsTpl = [];
  loaded = false;
  editMode = false;
  window = window;
  encodeURIComponent = encodeURIComponent;
  now = (new Date()).getTime();

  canvassers = [];
  newContacts = [];
  questions = [];
  questionValues = [];
  filteredQuestions: Observable<string[]>;
  surveyQuestionTxt: '';
  surveyQFocus = 0;
  saving = false;

  IsLeader = false;

  isMenuOpen = false;
  isMenuOpen2 = false;

  surveySets = [
    { name: 'Community Needs Survey', questions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14] },
    { name: 'Canvassing Survey', questions: [15, 16, 17, 18] }
  ];

  changeAddress() {

    const contact = this.contacts[this.current];

    const dialogRef = this.dialog.open(ConfirmAddressComponent, {
      width: '500px',
      disableClose: true,
      position: {
        top: '3vh'
      },
      data: {
        contact: contact,
        address: this.point.address,
        title: 'Change Address',
        bounds: [
          { lat: contact.point.lat - 0.000001, lng: contact.point.long - 0.000001 },
          { lat: contact.point.lat + 0.000001, lng: contact.point.long + 0.000001 }
        ]
      }
    });

    dialogRef.afterClosed().subscribe((GooglePlaceID) => {

      if (GooglePlaceID) {

        const url = '/contacts/' + GooglePlaceID + '/' + contact.kp_contactID;

        this.router.navigateByUrl(url);
        localStorage.path = url;

      }

    });

  }

  share() {

    const dialogRef = this.dialog.open(ShareContactComponent, {
      width: '500px',
      data: {
        title: 'Confirm Delete',
        contact: this.contacts[this.current],
        canvassers: this.canvassers
      }
    });

    dialogRef.afterClosed().subscribe(shareObj => {

      if (shareObj && shareObj.add) {

        this.contacts[this.current].shares = this.contacts[this.current].shares || [];
        this.contacts[this.current].shares.push(shareObj.add);

      }

      if (shareObj && shareObj.remove) {

        const index = this.contacts[this.current].shares.indexOf(shareObj.remove);

        if (index > -1) {
          this.contacts[this.current].shares.slice(index, 1);
        }

      }

    });

  }

  private _filter(value: string) {
    if (!value) {
      return this.questionsTpl;
    }

    const filterValue = ('' + value).toLowerCase();

    return this.questionsTpl.filter(option => option['title'].toLowerCase().includes(filterValue));
  }

  close() {
    this.saveOnClose = false;
    this.dialogRef.close();
  }

  logout() {
    this.db.q('magabooksApp:mbLogout').then(() => {
      this.router.navigateByUrl('login');
    });
  }

  getQuestionArray() {

    const qData = [];

    for (const [index, question] of this.questions) {
      const value = this.questionValues[index];

      if (!this.contacts[this.current].questions) {
        this.contacts[this.current].questions = [];
      }

      const savedQuestion = this.contacts[this.current].questions[index];
      qData.push({ id: question.kp_questionID, val: value, kp_id: savedQuestion ? savedQuestion.kp_id : null });
    }

    return qData;

  }

  deleteContact() {
    if (confirm('Delete ' + (this.contacts[this.current].name || 'Anonymous'))) {
      if (this.contacts[this.current].kp_contactID !== 'new') {
        this.db.q('magabooksApp:deleteContact', {
          contact: this.contacts[this.current]
        });
      }
      this.contacts.splice(this.current, 1);
      this.current = 0;
      this.contactBar.value = 0;
    }
  }

  saveOverlay() {
    this.saveContactData();
    this.close();
  }

  saveContactData() {

    this.saving = true;
    this.editMode = false;

    let placeID;
    let coords;

    if (this.point && this.point.lat) {
      placeID = this.point.GooglePlaceID;
      coords = { lat: this.point.lat, lng: this.point.long };
    } else if (this.data && this.data.address && this.data.address.geometry) {
      placeID = this.data.address.place_id;
      coords = { lat: this.data.address.geometry.location.lat, lng: this.data.address.geometry.location.lng };
    }

    if (!placeID) {
      return;
    }

    this.saveQuestionToContact();

    this.db.q('magabooksApp:saveContacts', {
      address: this.point.address,
      contacts: this.contacts,
      le: this.db.le.kf_personID,
      skipVisit: 1,
      placeID: placeID,
      coords: coords
    }).then((data) => {

      this.db.updateReminders();

      this.saving = false;

      let newIndex = 0,
        newVisitIndex = 0,
        newFollowupIndex = 0;

      for (const contact of this.contacts) {

        if (contact['kp_contactID'] === 'new') {
          const newContactObj = data['contacts'][newIndex];
          Object.assign(contact, newContactObj);
          newIndex++;
        }

        for (const row of contact.visits) {
          if (row.kp_visitID === 'new') {
            const newVisitObj = data['visits'][newVisitIndex];
            Object.assign(row, newVisitObj);
            newVisitIndex++;
          }
        }

        for (const row of contact.studies) {
          if (row.kp_visitID === 'new') {
            const newVisitObj = data['studies'][newVisitIndex];
            Object.assign(row, newVisitObj);
            newVisitIndex++;
          }
        }

        for (const row of contact.followup) {
          if (row.kp_followupID === 'new') {
            const newFollowupObj = data['followup'][newFollowupIndex];
            Object.assign(row, newFollowupObj);
            newFollowupIndex++;
          }
        }

      }

    });

  }

  removeReminder(followup) {
    this.utils.delete(this.contacts[this.current].followup, followup);
  }

  completeFollowup(followup, silent?) {
    this.removeReminder(followup);

    this.db.q('magabooksApp:completeFollowup', { obj: followup, delete: 0, silent: silent || 0 }).then((response) => {

      if (response['obj']) {

        this.contacts[this.current].followup.push(response['obj']);

      }

      this.db.updateReminders();

    });
  }

  deleteFollowup(followup) {

    if (followup.repeat_frequency) {

      const dialogRef = this.dialog.open(YesNoDialogueComponent, {
        width: '500px',
        data: {
          message: 'Delete this reminder only, or all future re-occuring reminders?',
          title: 'Confirm Delete',
          actions: [{
            fn: 'close',
            label: 'Cancel'
          }, {
            fn: 'save',
            args: 'this',
            label: 'This Reminder Only'
          }, {
            fn: 'save',
            args: 'all',
            label: 'This & Future Reminders'
          }]
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {

          if (result === 'this') {

            this.completeFollowup(followup, 1);

          }

          this.doDeleteReminder(followup);

        }

      });

    } else {

      this.doDeleteReminder(followup);

    }
  }

  doDeleteReminder(followup) {
    this.removeReminder(followup);
    this.db.q('magabooksApp:completeFollowup', { obj: followup, delete: 1 }).then(() => {
      this.db.updateReminders();
    });
  }

  addFollowup() {
    this.addVisit('followup');
  }

  editFollowup(followup) {
    this.addVisit('followup', followup);
  }

  addBibleStudy() {
    this.addVisit('study');
  }
  deleteVisit(visit) {
    const type = visit.bible_study ? 'study' : 'visit';
    if (confirm('Delete this ' + type + '?')) {
      this.utils.delete(this.contacts[this.current].visits, visit);
      this.db.q('magabooksApp:deleteVisit', { obj: visit });
    }
  }
  editVisit(visit) {
    const type = visit.bible_study ? 'study' : 'visit';
    this.addVisit(type, visit);
  }

  addVisit(what?, editRow?) {

    what = what || 'visit';

    this.isMenuOpen = false;

    const dialogRef = this.dialog.open(AddVisitComponent, {
      width: '900px',
      disableClose: true,
      autoFocus: false,
      position: {
        top: '3vh'
      },
      data: {
        contact: this.contacts[this.current],
        editRow: editRow || null,
        what: what
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        if (result.completeFollowup.length) {
          for (const followup of result.completeFollowup) {
            followup.completed = 1;
            this.completeFollowup(followup);
          }
        }

        if (this.contacts[this.current].followup) {
          this.contacts[this.current].followup = this.sort.transform(this.contacts[this.current].followup, 'time');
        }

        this.saveContactData();

      }

    });

  }

  createSet(set) {

    const qs = set.questions.reverse();
    for (const id of qs) {
      const questionObj = this.utils.find(this.questionsTpl, 'kp_questionID', id);
      this.addSurveyQuestion(questionObj, null, true);
    }

  }

  editContact() {
    if (this.editMode) {
      this.editMode = false;
      for (const row of this.contacts[this.current].phones) {
        if (!row.number) {
          this.utils.delete(this.contacts[this.current].phones, row);
        }
      }
      this.saveContactData();
    } else {
      this.editMode = true;
      this.isMenuOpen = false;
      if (!this.contacts[this.current].phones) {
        this.contacts[this.current].phones = [{}];
      }
    }
  }

  newContact() {
    this.contacts.push({ kp_contactID: 'new', questions: [], phones: [{}] });
    this.current = this.contacts.length - 1;
    this.editMode = true;
  }

  saveQuestionToContact() {
    this.contacts[this.current].questions = this.getQuestionArray();
  }

  resetQuestions() {

    this.questions = [];
    this.questionValues = [];

  }

  initQuestions(contact, initial?) {

    if (!initial) {
      this.saveQuestionToContact();
      this.resetQuestions();
    }

    for (const question of contact.questions) {
      const questionObj = this.utils.find(this.questionsTpl, 'kp_questionID', question.id);
      this.addSurveyQuestion(questionObj, question.val, true);
    }

  }

  addSurveyQuestion(question, value?, direction?) {

    let finalValue;

    if (typeof value === 'undefined' || value === null) {

      if (question['type'] === 'checkbox') {

        finalValue = [];

      } else {

        finalValue = null;

      }

    } else {

      finalValue = value;

    }

    this.surveyQuestionCtrl.reset();

    this.questionValues.push(finalValue);
    this.questions.push(question);

  }

  deleteSurveyQuestion(question, index) {
    if (confirm('Delete ' + question.title)) {
      this.questions.splice(index, 1);
      this.questionValues.splice(index, 1);
      this.contacts[this.current].questions.splice(index, 1);
    }
  }

  ngOnInit() {

    console.log('ngOnInit View Contacts');

    localStorage.path = this.router.url;

    this.db.getLeDetails().then(() => {

      this.sub.push(this.currentRoute.params.subscribe(params => {

        this.placeID = params.placeID || (this.data && (this.data.point || {}).GooglePlaceID) || (this.data.address || {}).place_id;
        this.contactID = params.contactID && parseInt(params.contactID, 10) || null;

        this.db.q('magabooksApp:viewContact', { placeID: this.placeID }).then((data) => {

          this.IsLeader = this.db.isLeader();

          this.contacts = data['contacts'];

          this.point = data['point'];

          if (!this.contacts || !this.contacts.length) {

            this.contacts = [];

            this.contacts.push({
              kp_contactID: 'new',
              kf_canvasserID: this.db.le.kp_canvasserID,
              kf_personID_le: this.db.le.kf_personID,
              phones: [{}]
            });

            this.point = {
              GooglePlaceID: this.data.address.place_id,
              address: this.data.address.formatted_address
            };

            this.editMode = true;

          }

          this.questionsTpl = data['surveyQuestions'];
          this.loaded = true;

          for (const contact of this.contacts) {
            contact.followup = this.sort.transform(contact.followup, 'time');
          }

          if (!this.contactID) {
            this.contactID = this.contacts[0].kp_contactID;
          }

          if (this.contactID) {
            const index = this.contacts.indexOf(this.utils.find(this.contacts, 'kp_contactID', this.contactID));
            if (index > -1) {
              this.current = index;
            }
          }

          if (!this.contacts[this.current].kf_personID_contact) {
            this.editMode = true;
            this.contacts[this.current].phones = [{}];
          }

          this.initQuestions(this.contacts[this.current], 1);

          this.filteredQuestions = this.surveyQuestionCtrl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );

          this.sub.push(this.db.data.subscribe(dbData => {

            if (!dbData) {
              return;
            }

            const canvassersObj = {},
              canvassers = [];

            for (const row of dbData['canvassers']) {
              if (!canvassersObj[row['kf_personID']]) {
                canvassersObj[row['kf_personID']] = row;
                canvassers.push(row);
              }
            }

            canvassers.sort((a, b) => a.name.localeCompare(b.name));
            this.canvassers = canvassers;

          }));

        });

      }));

    });

  }

  canvasserChanged() {
    const contact = this.contacts[this.current];
    const canvasser = contact && this.utils.find(this.canvassers, 'kp_canvasserID', contact.kf_canvasserID);
    if (canvasser) {
      contact.kf_personID_le = canvasser.kf_personID;
    }
  }

  ngOnDestroy() {

    if (this.saveOnClose) {
      this.saveContactData();
    }

    for (const sub of this.sub) {
      sub.unsubscribe();
    }

    console.log('Destroy View Contacts');
    

  }


}
