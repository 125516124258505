import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';

@Component({
  selector: 'app-yes-no-dialogue',
  templateUrl: './yes-no-dialogue.component.html',
  styleUrls: ['./yes-no-dialogue.component.css']
})
export class YesNoDialogueComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<YesNoDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  process(action) {
    this[action.fn](action.args || null);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save(args) {
    
    this.dialogRef.close(args);

  }

  close(args?) {

    this.dialogRef.close();

  }

}
