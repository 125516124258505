import { NgModule } from '@angular/core';
import {
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatRippleModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatSelectModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatBottomSheetModule,
  MatTooltipModule,
  MatButtonToggleModule,
  MatExpansionModule,
  MatSlideToggleModule,
  MatAutocompleteModule,
  MatRadioModule,
  MatBadgeModule,
  MatTabsModule
} from '@angular/material';

@NgModule({
  imports: [
    MatToolbarModule,
    MatBottomSheetModule,
    MatTooltipModule,
    MatRippleModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatBadgeModule,
    MatTabsModule
  ],
  exports: [
    MatBottomSheetModule,
    MatToolbarModule,
    MatRippleModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatBadgeModule,
    MatTabsModule
  ],
})
export class ImportMaterialModules { }