import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {

  transform(num: any, args?: any): any {

    if (!num) {
       return num;
    }

    num = num.toString();

    let output = num.replace(/[^0-9]/g, '');

    if (!output) {
       return num;
    }

    let len = output.length;

    if (len === 9) {
      output = '0' + output;
      num = '0' + num;
      len = 10;
    }

    const two_chars = output.substring(0, 2);
    const three_chars = output.substring(0, 3);

    if (len === 11 && three_chars === '614') {

      output = '+' + output.substring(0, 2) + ' ' + output.substring(2, 5) + ' ' + output.substring(5, 8) + ' ' + output.substring(8, 11);

    } else if (len === 8) {

      output = output.substring(0, 4) + ' ' + output.substring(4, 8);

    } else if (len === 10) {
      if (two_chars === '04') {
        output = output.substring(0, 4) + ' ' + output.substring(4, 7) + ' ' + output.substring(7, 10);
      } else {
        output = '(' + two_chars + ')' + ' ' + output.substring(2, 6) + ' ' + output.substring(6, 10);
      }
    } else {

      return num;
    }

    return output;

  }

}
