import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DbService } from '../db.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-simple-event-login',
  templateUrl: './simple-event-login.component.html',
  styleUrls: ['./simple-event-login.component.css']
})
export class SimpleEventLoginComponent implements OnInit {

  constructor(
    private db: DbService,
    private router: Router
  ) { }

  form: FormGroup = new FormGroup({
    password: new FormControl('')
  });

  loading = false;
  error = '';

  ngOnInit() {
  }

  submit() {
    if (this.form.value.password) {

      const eventID = window.location.pathname.split('/')[2];
      this.error = '';

      this.db.q('magabooksApp:eventLogin', {
        pwd: this.form.value.password,
        event: eventID
      }).then((res) => {

        if (res['eventLogin']) {

          this.db.eventLoaded = false;
          this.db.loadEvent(eventID);
          this.router.navigateByUrl('/SimpleEvent/' + eventID + '/home');

        } else {
          
          this.error = 'Wrong Password';

        }

      });

    }
  }

}
