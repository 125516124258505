import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { TuilderUtilities } from '../tuilder-utilities.service';
import { DbService } from '../db.service';
import { SortPipe } from '../sort.pipe';
import { firstBy } from 'thenby';

@Component({
  selector: 'app-my-contacts',
  templateUrl: './my-contacts.component.html',
  styleUrls: ['./my-contacts.component.css']
})
export class MyContactsComponent implements OnInit, OnDestroy {

  @ViewChild('contactBar') contactBar;

  color;
  search = '';
  window = window;
  encodeURIComponent = encodeURIComponent;
  contacts = [];
  canvassers = [];
  isMenuOpen = false;
  sort = 'priority';
  group = 'my';
  selectedLe = parseInt(localStorage.selectedLe, 10) || null;
  selectedLeObj;
  le;
  reminders = [];
  filteredReminders = [];
  currentContactGroup = 0;
  lastedSharedPersonCheck = null;
  remindersNum = 7;
  showRemindersMoreBtn = false;

  constructor(
    protected utils: TuilderUtilities,
    protected db: DbService,
    protected router: Router,
    public dialog: MatDialog,
    protected sortPipe: SortPipe,
    protected currentRoute: ActivatedRoute
  ) { }

  contactSearchCtrl = new FormControl();
  filteredContacts: Observable<string[]>;

  changeSort(what?) {
    if (what === 'le') {
      this.filterReminders();
      localStorage.selectedLe = this.selectedLe;
      this.selectedLeObj = this.utils.find(this.canvassers, 'kf_personID', this.selectedLe);
    }
    this.contactSearchCtrl.updateValueAndValidity();
  }

  findContact(contactID) {
    return this.utils.find(this.contacts, 'kp_contactID', contactID);
  }

  getContactUrl(contact) {
    return '/contacts/' + contact.point.GooglePlaceID + '/' + contact.kp_contactID;
  }

  open(contact) {

    if (contact.point && contact.point.GooglePlaceID) {

      this.router.navigateByUrl(this.getContactUrl(contact));

    } else {

      const addressTxt = Object.values(contact.point.address).join(' ');

      const dialogRef = this.dialog.open(ConfirmAddressComponent, {
        width: '500px',
        disableClose: true,
        position: {
          top: '3vh'
        },
        data: {
          contact: contact,
          address: addressTxt,
          bounds: [
            { lat: contact.point.lat - 0.000001, lng: contact.point.long - 0.000001 },
            { lat: contact.point.lat + 0.000001, lng: contact.point.long + 0.000001 }
          ]
        }
      });

      dialogRef.afterClosed().subscribe((GooglePlaceID) => {

        if (GooglePlaceID) {

          this.router.navigateByUrl(this.getContactUrl(contact));

        }


      });

    }

  }

  isSharedWithMe(contact, personID?) {

    const selectedPersonID = personID || this.selectedLe;

    if (selectedPersonID === this.lastedSharedPersonCheck) {
      return contact.is_shared;
    }

    const loggedInPersonID = parseInt(this.db.le.kf_personID, 10);

    const isLeader = this.le.is_leader ? true : false;
    const ViewingOtherPerson = isLeader && selectedPersonID !== loggedInPersonID;

    let sharedWithMe;

    if (ViewingOtherPerson) {
      sharedWithMe = this.utils.find(contact.shares, 'kf_personID_shared', selectedPersonID);
    } else {
      sharedWithMe = contact.is_shared;
    }

    contact.is_shared = sharedWithMe ? 1 : 0;

    return sharedWithMe;

  }

  protected _filterContacts(value: string) {

    let contacts;

    const selectedPersonID = this.selectedLe;

    const who = ['all', 'me', 'shared'][this.contactBar.value];

    if (this.selectedLe !== null && this.selectedLe.toString() !== 'NaN') {

      contacts = this.contacts.filter((row) => {

        return !selectedPersonID || (parseInt(row.kf_personID_le, 10) === selectedPersonID) || this.isSharedWithMe(row);

      });

    } else {

      contacts = this.contacts;

    }

    contacts = contacts.filter((row) => {
      return row.name;
    });

    if (who === 'all') {

      contacts = contacts;

    } else {

      contacts = contacts.filter((row) => {

        const sharedWithMe = this.isSharedWithMe(row);

        return who === 'shared' ? sharedWithMe : !sharedWithMe;

      });

    }

    const dynamicSort = function (property) {
      let sortOrder = 1;
      if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a, b) {
        const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
      };
    };

    if (this.sort === 'priority') {

      // first by length of name descending, then by population descending, then by ID ascending
      contacts.sort(
        firstBy(function (v1: any, v2: any) { return v1.BibleContact - v2.BibleContact; }, -1).thenBy('time', -1)
      );

    } else if (this.sort === 'newest') {

      contacts.sort(dynamicSort('time')).reverse();

    }

    this.lastedSharedPersonCheck = selectedPersonID;

    if (!value) {
      return contacts;
    }

    const filterValue = ('' + value).toLowerCase();

    return contacts.filter(option => (option['name']||'').toLowerCase().includes(filterValue));
  }

  ngOnInit() {

    console.log('ngOnInit My Contacts');

    localStorage.path = '/contacts';

    this.db.q('magabooksApp:myContacts').then((data) => {

      this.le = data['le'];
      this.selectedLe = parseInt(localStorage.selectedLe || this.le['kf_personID'], 10);

      const cvsObj = {};

      for (const canvasser of data['canvassers']) {
        if (canvasser) {
          if (!cvsObj[canvasser['kf_personID']]) {
            cvsObj[canvasser['kf_personID']] = canvasser;
          }
        }

      };

      this.canvassers = Object.values(cvsObj);

      this.selectedLeObj = this.utils.find(this.canvassers, 'kf_personID', this.selectedLe);

      for (const row of data['contacts']) {
        row.color = row.name && this.utils.colorFromString(row.name) || '232323';
        const name = row.name || 'Anonymous';
        const namesplit = name.split(' ');
        let abbrev = '';
        if (row.followup) {
          for (const reminder of row.followup) {
            reminder.contactName = name;
            reminder.placeID = row.point.GooglePlaceID;
          }
          this.reminders = this.reminders.concat(row.followup);
        }
        if (namesplit.length === 1) {

          abbrev += name.charAt(0);
          abbrev += name.charAt(1);

        } else {

          for (const bit of namesplit) {
            abbrev += bit.charAt(0);
          };

        }

        abbrev = abbrev.toUpperCase();

        row.abbrev = abbrev;

      };

      this.reminders = this.sortPipe.transform(this.reminders, 'time');

      this.contacts = data['contacts'];

      this.filteredContacts = this.contactSearchCtrl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterContacts(value))
        );


      this.filterReminders();

    });

    this.color = this.utils.colorFromString('Emmanuel Higgins');

  }

  filterReminders() {

    if (!this.selectedLe || this.selectedLe === null) {
      this.filteredReminders = this.reminders;
    } else {
      this.filteredReminders = this.reminders.filter((row) => {
        const contact = this.utils.find(this.contacts, 'kp_contactID', row.kf_contactID);
        return (parseInt(row.kf_personID_le, 10) === this.selectedLe) ||
          (this.selectedLe === parseInt(this.db.le.kf_personID, 10) && contact.is_shared);
      });
    }

    const length = this.filteredReminders && this.filteredReminders.length;

    this.remindersNum = length > 10 ? 10 : length;
    this.showRemindersMoreBtn = length > this.remindersNum;

  }

  completeFollowup(followup, silent?) {

    this.utils.delete(this.reminders, followup);

    this.db.q('magabooksApp:completeFollowup', { obj: followup, delete: 0, silent: silent || 0 }).then((response) => {

      if (response['obj']) {

        const contact = this.utils.find(this.contacts, 'kp_contactID', response['obj'].kf_contactID);
        if (contact) {
          response['obj'].contactName = contact.name;
          response['obj'].placeID = contact.point.GooglePlaceID;
        }
        this.reminders.push(response['obj']);
        this.reminders = this.sortPipe.transform(this.reminders, 'time');

      }

      this.db.updateReminders();

    });
  }

  logout() {
    this.db.q('magabooksApp:mbLogout').then(() => {
      this.router.navigateByUrl('login');
    });
  }

  ngOnDestroy() {

    console.log('ngOnDestroy My Contacts');

  }

}

@Component({
  selector: 'app-confirm-address-component',
  templateUrl: './confirm-address-component.html',
  styleUrls: ['./confirm-address.css']
})

export class ConfirmAddressComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmAddressComponent>,
    private db: DbService,
    private utils: TuilderUtilities,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  adrSearch = '';
  searchResponseError = false;
  searchResponse = [];
  loading = false;

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

    this.adrSearch = this.data['address'];
    this.addressLookup(this.adrSearch);

  }

  addressLookup(keywords?) {

    keywords = keywords || this.adrSearch;

    const bounds = this.data['bounds'];

    const url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURIComponent(keywords) +

      // Add search bounding to map viewport area
      '&bounds=' +
      bounds[0].lat +
      ',' +
      bounds[0].lng + '|' +
      bounds[1].lat +
      ',' +
      bounds[1].lng +

      '&key=' +
      this.db.mapsApiKey;

    this.db.getJson(
      url
    ).subscribe(data => {

      this.searchResponseError = false;

      const response = data['results'];

      this.searchResponse = response;

      if (response.length === 1) {

      } else if (!response.length) {

        this.searchResponseError = true;

      }

    });

  }

  saveContactData(adr) {

    const placeID = adr.place_id;
    const coords = adr && adr.geometry && adr.geometry.location;

    return this.db.q('magabooksApp:saveContacts', {
      address: adr.formatted_address,
      contacts: [this.data['contact']], // todo
      placeID: placeID,
      coords: coords
    });

  }

  save(adr) {

    this.loading = true;

    this.saveContactData(adr).then(() => {

      this.dialogRef.close(adr.place_id);

    });

  }

  close() {
    this.dialogRef.close();
  }

}
