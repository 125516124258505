import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { DbService } from '../db.service';
import { TuilderUtilities } from '../tuilder-utilities.service';
import * as moment from 'moment-timezone';

// TODO: Replace this with your own data model type
export interface CanvassersTableRow {
  name: string;
  gross: number;
  net: number;
  magabooks: number;
  spiritual: number;
  dropdown: number;
  dvdOther: number;
  days: number;
  booksPerDay: number;
  donationsPerDay: number;
}

/**
 * Data source for the MyTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class MyTableDataSource extends DataSource<CanvassersTableRow> {
  data: CanvassersTableRow[] = [];

  constructor(
    private paginator: MatPaginator,
    private sort: MatSort,
    private db: DbService,
    private utils: TuilderUtilities
  ) {

    super();

  }

  personID;
  leData;
  firstCanvasserSubscribeEvent = 1;

  build(data) {

    this.data = [];

    const inventory = data['inventory'].reduce((result, item) => {
      (result[item['kf_programID']] = result[item['kf_programID']] || []).push(item);
      return result;
    }, {}),
      canvassers: Object = {},
      bookCode2Name: Object = {};

    for (const topsheet of data['topsheets']) {

      const canvasser = this.utils.find(data['canvassers'], 'kp_canvasserID', topsheet['kf_canvasserID']);

      if (!canvasser) {
        console.log('orphaned topsheet', topsheet);
        return;
      }

      canvassers[canvasser['kf_personID']] = canvassers[canvasser['kf_personID']] || {
        name: '',
        books: {},
        magabooks: 0,
        dropdown: 0,
        other: 0,
        bookCost: 0,
        donations: 0,
        spiritualBooks: 0,
        days: [],
        topsheets: []
      };

      const row = canvassers[canvasser['kf_personID']];

      if (!this.personID) {

        const programInventory = inventory[topsheet['kf_programID']];

        for (let [bookCode, qty] of Object.entries(topsheet['items']) as any) {

          qty = parseInt(qty, 0) || 0;

          row.books[bookCode] = row.books[bookCode] || 0;
          row.books[bookCode] += qty;

          const inventoryItem = this.utils.find(programInventory, 'code', bookCode);

          if (inventoryItem) {

            row.bookCost += inventoryItem['cost_price'] * qty;

            row[{
              Magabook: 'magabooks',
              Dropdown: 'dropdown'
            }[inventoryItem['type']] || 'other'] += qty;

            if (inventoryItem['message']) {
              row.spiritualBooks += qty;
            }

            if (!bookCode2Name[bookCode]) {
              bookCode2Name[bookCode] = inventoryItem['name'];
            }

          }
        }

        row.donations += this.utils.addSum([topsheet['notes'], topsheet['coins'], topsheet['paypal']]);

        const day = moment(parseInt(topsheet['date'], 0)).startOf('day').valueOf();

        if (row.days.indexOf(day) < 0) {
          row.days.push(day);
        }

        row.topsheets.push(topsheet);

        row.name = canvasser['name'];

      }

    }

    for (const row of Object.values(canvassers)) {
      const newRow = <CanvassersTableRow>{
        name: row['name'],
        gross: row['donations'],
        net: row['donations'] - row['bookCost'],
        magabooks: row['magabooks'],
        spiritual: (row['spiritualBooks'] / row['magabooks']) * 100,
        dropdown: row['dropdown'],
        dvdOther: row['other'],
        days: row['days'].length,
        booksPerDay: row['magabooks'] / row['days'].length,
        donationsPerDay: row['donations'] / row['days'].length
      };
      this.data.push(newRow);
    }

  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<CanvassersTableRow[]> {

    this.db.canvasser.subscribe(personID => {

      this.personID = personID;
      if (!this.firstCanvasserSubscribeEvent) {
        this.build(this.leData);
      } else {
        this.firstCanvasserSubscribeEvent = 0;
      }

    });

    this.db.data.subscribe(data => {

      this.leData = data;

      this.build(data);

    });

    this.sort.start = 'desc';

    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.db.data,
      this.db.canvasser,
      this.sort.sortChange
    ];

    // Set the paginators length
    this.paginator.length = this.data.length;

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: CanvassersTableRow[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: CanvassersTableRow[]) {

    if (!this.sort.active) {
      this.sort.active = 'gross';
      this.sort.direction = 'desc';
    }

    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'name': return compare(a.name, b.name, isAsc);
        case 'gross': return compare(+a.gross, +b.gross, isAsc);
        case 'net': return compare(+a.net, +b.net, isAsc);
        case 'magabooks': return compare(+a.magabooks, +b.magabooks, isAsc);
        case 'spiritual': return compare(+a.spiritual, +b.spiritual, isAsc);
        case 'dropdown': return compare(+a.dropdown, +b.dropdown, isAsc);
        case 'dvdOther': return compare(+a.dvdOther, +b.dvdOther, isAsc);
        case 'days': return compare(+a.days, +b.days, isAsc);
        case 'booksPerDay': return compare(+a.booksPerDay, +b.booksPerDay, isAsc);
        case 'donationsPerDay': return compare(+a.donationsPerDay, +b.donationsPerDay, isAsc);
        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
