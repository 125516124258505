import { SortPipe } from './../sort.pipe';
import { MatDialog } from '@angular/material';
import { DbService } from 'src/app/db.service';
import { MyContactsComponent } from './../my-contacts/my-contacts.component';
import { Component, OnInit } from '@angular/core';
import { TuilderUtilities } from '../tuilder-utilities.service';
import { Router, ActivatedRoute } from '@angular/router';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-simple-event-contacts',
  templateUrl: './simple-event-contacts.component.html',
  styleUrls: ['./../my-contacts/my-contacts.component.css', './simple-event-contacts.component.css']
})
export class SimpleEventContactsComponent extends MyContactsComponent implements OnInit {

  constructor(
    protected utils: TuilderUtilities,
    protected db: DbService,
    protected router: Router,
    public dialog: MatDialog,
    protected sortPipe: SortPipe,
    protected currentRoute: ActivatedRoute
  ) {
    super(utils, db, router, dialog, sortPipe, currentRoute);
    localStorage.path = window.location.pathname;
  }

  eventID = window.location.pathname.split('/')[2];

  getContactUrl(contact) {

    return '/SimpleEvent/' +
      this.eventID +
      '/contacts/' +
      contact.point.GooglePlaceID + '/' +
      contact.kp_contactID;
  }

  ngOnInit() {

    this.contactBar = { value: 'all' };

    this.db.loadEvent(this.eventID).then((data) => {

      this.le = this.db.le = {
        is_leader: 1,
        kf_personID: 0,
        kp_canvasserID: 0
      };

      this.selectedLe = 0;

      const cvsObj = {};

      for (const canvasser of data['canvassers']) {
        if (canvasser) {
          if (!cvsObj[canvasser['kf_personID']]) {
            cvsObj[canvasser['kf_personID']] = canvasser;
          }
        }

      }

      this.canvassers = Object.values(cvsObj);

      this.selectedLeObj = this.utils.find(this.canvassers, 'kf_personID', this.selectedLe);

      for (const row of data['contacts']) {
        row.color = row.name && this.utils.colorFromString(row.name) || '232323';
        const name = row.name || 'Anonymous';
        const namesplit = name.split(' ');
        let abbrev = '';
        if (row.followup) {
          for (const reminder of row.followup) {
            reminder.contactName = name;
            reminder.placeID = row.point.GooglePlaceID;
          }
          this.reminders = this.reminders.concat(row.followup);
        }
        if (namesplit.length === 1) {

          abbrev += name.charAt(0);
          abbrev += name.charAt(1);

        } else {

          for (const bit of namesplit) {
            abbrev += bit.charAt(0);
          }

        }

        abbrev = abbrev.toUpperCase();

        row.abbrev = abbrev;

      }

      this.reminders = this.sortPipe.transform(this.reminders, 'time');

      this.contacts = data['contacts'];

      console.log(this.contacts);

      this.filteredContacts = this.contactSearchCtrl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterContacts(value))
        );


      this.filterReminders();

    });

    this.color = this.utils.colorFromString('Emmanuel Higgins');

  }

}
