import { YesNoDialogueComponent } from './../../yes-no-dialogue/yes-no-dialogue.component';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { TuilderUtilities } from './../../tuilder-utilities.service';
import { Component, OnInit, Inject } from '@angular/core';
import { DbService } from 'src/app/db.service';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-share-contact',
  templateUrl: './share-contact.component.html',
  styleUrls: ['./share-contact.component.css']
})
export class ShareContactComponent implements OnInit {

  constructor(
    private utils: TuilderUtilities,
    private db: DbService,
    private dialogRef: MatDialogRef<ShareContactComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  contact;
  search = '';
  contactSearchCtrl = new FormControl();
  filteredCanvassers = this.data.canvassers;

  ngOnInit() {

    this.contact = this.data.contact;

    this.filteredCanvassers = this.contactSearchCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterCanvassers(value))
      );

  }

  _filterCanvassers(value: string) {

    if (!value) {
      return this.data.canvassers;
    }

    const filterValue = ('' + value).toLowerCase();

    return this.data.canvassers.filter(option => option['name'].toLowerCase().includes(filterValue));

  }

  deleteShare(share) {

    const dialogRef = this.dialog.open(YesNoDialogueComponent, {
      width: '500px',
      data: {
        message: 'Stop sharing with ' + share.canvasser.name + '?',
        title: 'Remove ' + share.canvasser.name,
        actions: [{
          fn: 'close',
          label: 'Cancel'
        }, {
          fn: 'save',
          args: 1,
          label: 'Remove'
        }]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.utils.delete(this.contact.shares, share);

        this.db.q('magabooksApp:shareContact', { obj: share, delete: 1 });

      }
    });

  }

  share(le) {

    const me = this.db.le;

    const dialogRef = this.dialog.open(YesNoDialogueComponent, {
      width: '500px',
      data: {
        message: 'Share contact with ' + le.name + '?',
        title: 'Confirm Share',
        actions: [{
          fn: 'close',
          label: 'Cancel'
        }, {
          fn: 'save',
          args: 1,
          label: 'Share'
        }]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const shareObj = {
          kf_canvasserID_owner: this.contact.kf_canvasserID,
          kf_canvasserID_shared: le.kp_canvasserID,
          kf_personID_owner: this.contact.kf_personID_le,
          kf_personID_shared: le.kf_personID,
          kf_contactID: this.contact.kp_contactID
        };

        this.db.q('magabooksApp:shareContact', { obj: shareObj }).then((response) => {
          if (response['obj']) {
            this.utils.extend(shareObj, response['obj']);
          }
        });

        this.dialogRef.close({add: shareObj});

      }
    });

  }

  close() {
    this.dialogRef.close();
  }

}
