import { Component, OnInit, OnDestroy, Inject } from '@angular/core'
import { DbService } from '../db.service'
import { TuilderUtilities } from '../tuilder-utilities.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'

import { DataSource } from '@angular/cdk/collections'
import { Observable, of as observableOf } from 'rxjs'

@Component({
  selector: 'download-file-dialog',
  templateUrl: './download-file-dialog.html',
})

export class downloadFileDialog {

  startDate: any
  endDate: any

  constructor(
    public dialogRef: MatDialogRef<downloadFileDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close()
  }

  ngOnInit() {

  }

  save() {
    window.open(this.data['url'])
    this.dialogRef.close()
  }

  close() {
    this.dialogRef.close()
  }

}

interface bookStats {
  name: string,
  type: string,
  qty: number
}

@Component({
  selector: 'my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.css']
})
export class MyDashboardComponent implements OnInit, OnDestroy {

  private loading: boolean = false;

  constructor(
    private db: DbService,
    private utils: TuilderUtilities,
    public dialog: MatDialog
  ) { };

  fabDisabled = false;

  books: bookStats[] = [];

  booksDataSource

  bookCols = ['name', 'qty'];

  donations = {
    notes: 0,
    coins: 0,
    paypal: 0
  };

  grossDonations = 0;
  totalExpenses = 0;

  expenses = {
    bookCosts: 0,
    fuel: 0,
    food: 0,
    other: 0
  };

  // DonationsData
  donationsLabels: string[] = ['Notes', 'Coins', 'PayPal'];
  donationsData: number[] = [0, 0, 0];
  expensesLabels: string[] = ['Book Costs', 'Fuel', 'Food', 'Other'];
  expensesData: number[] = [0, 0, 0, 0];

  incrementTimeByGroupingUnit(time: number, unit: string) {

    let date = new Date(time),
      output

    if (unit == 'd' || unit == 'w') {

      output = date.setDate(date.getDate() + { d: 1, w: 7 }[unit])

    } else if (unit == 'm') {

      output = date.setMonth(date.getMonth() + 1)

    } else if (unit == 'y') {

      output = date.setFullYear(date.getFullYear() + 1)

    }

    return output

  }

  getGroupLabelForTime(time: number, previous: any, groupBy: string) {

    let date = new Date(time),
      output

    if ({ d: 1, w: 1 }[groupBy]) { // day or week

      output = {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate()
      }

      output['value'] = output.day

      if (!previous || output.month != previous['month']) {
        output['value'] = this.utils.getMonthShortName(output.month) + ' ' + output['value']
      }

      if (previous && output.year != previous['year']) {
        output['value'] = output['value'] + ', ' + output.year
      }

    } else if (groupBy == 'm') { // month

      output = {
        year: date.getFullYear(),
        month: date.getMonth()
      }

      output['value'] = this.utils.getMonthShortName(output.month)

      if (!previous || output.year != previous['year']) {
        output['value'] = output['value'] + ' ' + output.year
      }

    } else { // year

      output = {
        value: date.getFullYear()
      }

    }

    return output

  }

  getTimeGroupFromTime(time: number, groupBy: string) {

    let startDateObj = new Date(time),
      output

    if (groupBy == 'd') {

      output = new Date(startDateObj.setHours(0, 0, 0, 0))

    } else if (groupBy == 'w') {

      var day = startDateObj.getDay()

      output = new Date(startDateObj.getFullYear(), startDateObj.getMonth(), startDateObj.getDate() - day)

    } else if (groupBy == 'm') {

      output = new Date(startDateObj.getFullYear(), startDateObj.getMonth(), 1)

    } else if (groupBy == 'y') {

      output = new Date(startDateObj.getFullYear(), 1, 1)

    }

    return output

  }

  openDialog(url: string, filename: string) {

    this.dialog.open(downloadFileDialog, {
      width: '500px',
      data: {
        url: url,
        name: filename
      }
    })

  }

  download() {

    if (!this.data) return

    this.fabDisabled = true

    let start = this.getTimeGroupFromTime(parseInt(this.data['start']) * 1000, 'd'),
      curTime = start.valueOf(),
      endTime = parseInt(this.data['end']) * 1000,
      days = []

    while (curTime < endTime) {
      days.push(curTime)
      curTime = this.incrementTimeByGroupingUnit(curTime, 'd')
    }

    let topsheets = {}

    for (const topsheet of this.data['topsheets']) {

      let canvasser = this.utils.find(this.data['canvassers'], 'kp_canvasserID', topsheet['kf_canvasserID'])

      if (!this.personID || canvasser['kf_personID'] == this.personID) {

        let day = this.getTimeGroupFromTime(parseInt(topsheet['date']), 'd').valueOf()
        topsheets[day] = topsheets[day] || []
        topsheets[day].push(topsheet['kp_topsheetID'])

      }

    };

    this.db.q("magabooksApp:download", {
      topsheets: topsheets,
      days: days
    }).then((response) => {

      this.fabDisabled = false

      if (response['url']) this.openDialog(response['url'], response['fileName'])

    })
  }

  buildModel(data: any) {

    this.donations = {
      notes: 0,
      coins: 0,
      paypal: 0
    }

    this.grossDonations = 0
    this.totalExpenses = 0

    this.expenses = {
      bookCosts: 0,
      fuel: 0,
      food: 0,
      other: 0
    }

    if (!data || !data['start']) {
      return
    }

    this.books = []

    let dayDif = Math.ceil(((data['end']) - (data['start'])) / (24 * 60 * 60)),
      groupBy

    if (dayDif < 90) {
      groupBy = 'd'
    } else if (dayDif < 500) {
      groupBy = 'w'
    } else if (dayDif < 1500) {
      groupBy = 'm'
    } else {
      groupBy = 'y'
    }

    let start = this.getTimeGroupFromTime(parseInt(data['start']) * 1000, groupBy),
      curTime = start.valueOf(),
      endTime = parseInt(data['end']) * 1000,
      labels = [],
      groupedData = {}

    while (curTime < endTime) {

      groupedData[curTime] = {
        Donations: [],
        Books: [],
        Canvassers: []
      }

      let labelObj = this.getGroupLabelForTime(curTime, labels.length && labels[labels.length - 1], groupBy)

      curTime = this.incrementTimeByGroupingUnit(curTime, groupBy)

      labels.push(labelObj)

    }

    let inventory = data['inventory'].reduce((result, item) => {
      (result[item['kf_programID']] = result[item['kf_programID']] || []).push(item)
      return result
    }, {}),
      bookStats = {},
      bookCode2Item = {}

    for (const topsheet of data['topsheets']) {

      let canvasser = this.utils.find(data['canvassers'], 'kp_canvasserID', topsheet['kf_canvasserID'])

      if (canvasser && (!this.personID || canvasser['kf_personID'] == this.personID)) {

        let timeGroup = this.getTimeGroupFromTime(parseInt(topsheet['date']), groupBy).valueOf(),
          programInventory = inventory[topsheet['kf_programID']],
          books = this.utils.addSum(Object.values(topsheet['items'] ? topsheet['items'] : {}))

        for (let [code, qty] of Object.entries(topsheet['items'] ? topsheet['items'] : {}) as any) {
          qty = parseInt(qty) || 0
          bookStats[code] = bookStats[code] || 0
          bookStats[code] += qty
          let inventoryItem = this.utils.find(programInventory, 'code', code)
          if (inventoryItem) {
            if (!bookCode2Item[code]) bookCode2Item[code] = inventoryItem
            this.expenses.bookCosts += parseFloat(inventoryItem['cost_price']) * qty
          }
        }

        if (groupedData[timeGroup]) {

          groupedData[timeGroup].Donations.push(
            this.utils.addSum([topsheet['notes'], topsheet['coins'], topsheet['paypal']])
          )

          if (topsheet['notes']) this.donations.notes += parseFloat(topsheet['notes']) || 0
          if (topsheet['coins']) this.donations.coins += parseFloat(topsheet['coins']) || 0
          if (topsheet['paypal']) this.donations.paypal += parseFloat(topsheet['paypal']) || 0

          groupedData[timeGroup].Books.push(books)

          if (!this.personID) groupedData[timeGroup].Canvassers.push(canvasser['kf_personID'])

        } else {

          console.warn("time group out of sync", timeGroup, topsheet)

        }

      }

    };

    for (const row of data['expenses']) {
      if (row) {

        this.expenses[
          ({
            Fuel: 'fuel',
            Food: 'food'
          }[row.type]) || 'other'] += parseFloat(row.price)

      }

    }

    this.donationsData = Object.values(this.donations)
    this.grossDonations = this.utils.addSum(this.donationsData)


    let expData = []

    for (const key of ['bookCosts',
    'food',
    'fuel',
    'other']) {
        expData.push(parseInt(this.expenses[key] || 0))
      }

    this.expensesData = expData

    this.totalExpenses = this.utils.addSum(this.expensesData)

    let books = {},
      totalMagabooks = 0,
      totalDropdowns = 0

    for (const [code, qty] of Object.entries(bookStats) as any) {
      let item = bookCode2Item[code]
      if (item) {

        if (item.type == 'Magabook') totalMagabooks += qty
        else if (item.type == 'Dropdown') totalDropdowns += qty

        books[item.type] = books[item.type] || []
        books[item.type].push(<bookStats>{
          name: item.name,
          type: item.type,
          qty: qty
        })
      }
    }

    this.books.push(<bookStats>{
      name: "Total Magabooks",
      type: "Total",
      qty: totalMagabooks
    })
    this.books.push(<bookStats>{
      name: "Total Dropdowns",
      type: "Total",
      qty: totalDropdowns
    })

    let keys = ['Magabook', 'Dropdown', 'DVD', 'Small Paperback']
    for (const type of keys) {
      if (books[type]) {
        books[type].sort((a, b) => b.qty - a.qty).reverse()
        for (const row of books[type]) {
          this.books.push(row)
        }
      }
    }

    this.booksDataSource = new BookStatDataSource(this.books)

    this.lineChartData[0].data.length = 0
    this.lineChartData[1].data.length = 0
    this.lineChartData[2].data.length = 0

    this.lineChartLabels = labels.map(l => l.value)

    for (const row of Object.values(groupedData)) {
      if (row['Donations']) this.lineChartData[0].data.push(row['Donations'].reduce((total, num) => ((total ? total : 0) + num), 0))
      if (row['Books']) this.lineChartData[1].data.push(row['Books'].reduce((total, num) => ((total ? total : 0) + num), 0))
      this.lineChartData[2].data.push(Array.from(new Set(row['Canvassers'])).length)
    }

  }

  data
  firstCanvasserSubscribeEvent = 1;
  personID

  subs = [];

  ngOnDestroy() {
    this.utils.unsubscribe(this.subs)
  }

  ngOnInit() {

    this.subs.push(this.db.canvasser.subscribe(personID => {
      this.personID = personID
      if (!this.firstCanvasserSubscribeEvent) this.buildModel(this.data)
      else this.firstCanvasserSubscribeEvent = 0
    }))

    this.subs.push(this.db.data.subscribe(data => {

      this.data = data

      this.buildModel(data)

    }))

  }

  public lineChartData: Array<any> = [
    { data: [], yAxisID: 'A', label: 'Donations' },
    { data: [], yAxisID: 'B', label: 'Books' },
    { data: [], yAxisID: 'C', label: 'Canvassers' }
  ];

  public lineChartLabels: Array<any> = [];

  public lineChartOptions: any = {
    responsive: true,
    scales: {
      yAxes: [{
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true,
          callback: (value) => {

            var formatter = new Intl.NumberFormat('au', { style: 'currency', currency: 'aud', minimumFractionDigits: 0, maximumFractionDigits: 0 })

            return formatter.format(value)

          }
        },
      }, {
        id: 'B',
        type: 'linear',
        display: false
      }, {
        id: 'C',
        type: 'linear',
        gridLines: false,
        ticks: {
          beginAtZero: true,
          callback: (value) => { if (value % 1 === 0) { return value } }
        },
        position: 'right',
      }]
    }
  };

  public lineChartLegend: boolean = true;
  public lineChartType: string = 'line';

}

export class BookStatDataSource extends DataSource<bookStats> {

  constructor(public stats: bookStats[]) {
    super()
  }

  connect(): Observable<bookStats[]> {
    return observableOf(this.stats)
  }

  disconnect() { }

}