import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material';
import { TuilderUtilities } from './../tuilder-utilities.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DbService } from 'src/app/db.service';
/// <reference types="@types/googlemaps" />
import { Component, OnInit } from '@angular/core';
import { MapComponent } from './../map/map.component';
import { RelativeTimePipe } from '../relative-time.pipe';

@Component({
  selector: 'app-simple-event-map',
  templateUrl: './simple-event-map.component.html',
  styleUrls: ['./../map/map.component.css', './simple-event-map.component.css']
})
export class SimpleEventMapComponent
  extends MapComponent
  implements OnInit {

  constructor(
    // private map: MapComponent,
    private route: ActivatedRoute,
    protected utils: TuilderUtilities,
    protected db: DbService,
    public dialog: MatDialog,
    protected router: Router,
    protected datePipe: DatePipe,
    protected timePipe: RelativeTimePipe

  ) {
    super(utils, db, dialog, router, datePipe, timePipe);

    localStorage.path = route.url;

  }

  ngOnInit() {

    this.db.loadEvent(window.location.pathname.split('/')[2]).then((res) => {
      if (res.eventLogin) {

        this.db.updateData({ canvassers: res.canvassers });

        this.EventMap = true;

        this.permissions = res.permissions;

        this.initMap().then(() => {

          const bounds = new google.maps.LatLngBounds();

          for (const marker of this.contactPinMarkers) {
            bounds.extend(marker.position);
          }

          for (const marker of this.bookPinMarkers[0]) {
            bounds.extend(marker.position);
          }

          this.map.fitBounds(bounds);

        });

      }
    });
  }

}
