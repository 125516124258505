import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) { }

  transform(time: any, hideHours?: any): any {

    time = parseFloat(time);

    if ((new Date()).getTime().toString().length - time.toString().length === 3) {
      time = time * 1000;
    }

    const now = (new Date()).getTime();
    const dayOfWeekNow = (new Date()).getDay(); // Sunday = 0, Monday = 1, etc.
    const dayOfWeekTime = (new Date(time)).getDay();
    const oneDay = 86400 * 1000; // seconds in one day
    const timeStr = this.datePipe.transform(time, ' @ h:mm a');
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    const dateDiffInDays = function (a, b) {
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    };

    let output;

    const futureTimeDiff = time - now;
    const pastTimeDiff = now - time;

    if (now < time) { // future date

      const difference = dateDiffInDays(new Date(now), new Date(time));
      const IsNextWeek = (dayOfWeekNow + difference) > 6 && difference + dayOfWeekNow < 13;

      if (futureTimeDiff < oneDay * 6 || IsNextWeek) { // less than six days in the future

        if (dayOfWeekNow === dayOfWeekTime && !IsNextWeek) {

          output = 'Today';

        } else if (dayOfWeekTime === (dayOfWeekNow === 6 ? 0 : dayOfWeekNow + 1) && !IsNextWeek) {

          output = 'Tomorrow';

        } else if (!IsNextWeek) {

          output = 'This ' + this.datePipe.transform(time, 'EEEE');

        } else {

          output = this.datePipe.transform(time, 'EEEE') + ' Next Week';

        }

        if (!hideHours) {
          output += ' ' + timeStr;
        }

      }

    } else { // past date

      const difference = dateDiffInDays(new Date(time), new Date(now));
      const IsLastWeek = (dayOfWeekTime + difference) > 6 && difference + dayOfWeekTime < 13;

      if (pastTimeDiff < oneDay * 6 || IsLastWeek) {

        if (dayOfWeekTime === dayOfWeekNow && !IsLastWeek) {

          output = 'Today';

        } else if (dayOfWeekTime === (!dayOfWeekNow ? 6 : dayOfWeekNow - 1) && !IsLastWeek) {

          output = 'Yesterday';

        } else if (!IsLastWeek || difference < 7) {

          output = 'Last ' + this.datePipe.transform(time, 'EEEE');

        } else {
          
          output = this.datePipe.transform(time, 'EEEE') + ' Last Week';

        }

        if (!hideHours) {
          output += ' ' + timeStr;
        }

      }

    }

    if (!output) {

      if (!hideHours) {
        output = this.datePipe.transform(time, 'MMMM d, ha');
      } else {
        output = this.datePipe.transform(time, 'MMMM d');
      }

    }

    return output;

  }

}
