import { Component, OnInit, Inject, ViewChild, AfterViewInit, ViewChildren, OnDestroy } from '@angular/core';
import { DbService } from '../db.service';
import { Router } from '@angular/router';
import { NotificationService, Notification } from '../notifications.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TuilderUtilities } from '../tuilder-utilities.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-choose-leader-component',
  templateUrl: './choose-leader-component.html',
})

export class ChooseLeaderComponent implements OnInit, AfterViewInit {

  @ViewChild('chooseLeader') chooseLeader;

  form: FormGroup;
  leader: any;
  leaders: Object[];

  constructor(
    public dialogRef: MatDialogRef<ChooseLeaderComponent>,
    private fb: FormBuilder,
    private db: DbService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.form = this.fb.group({
      leader: [null, Validators.required]
    });
    this.leaders = this.db.leaders;
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.chooseLeader.open();
    }, 150);

  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-create-contact',
  templateUrl: './app-create-contact.html',
  styleUrls: ['./app-create-contact.css']
})

export class CreateContactComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CreateContactComponent>,
    private fb: FormBuilder,
    private utils: TuilderUtilities,
    private db: DbService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  eventTypeValue = 0;
  followUp = false;
  init = false;
  followUpTime = {
    kp_followupID: 'new',
    date: null,
    title: '',
    timepicker: '',
    time: null,
    repeat_frequency: 0,
    kf_canvasserID: this.db['le'].kp_canvasserID,
  };
  repeatOptions = [
    { value: 0, label: 'Don\'t Repeat' },
    { value: '1w', label: 'Weekly' },
    { value: '2w', label: 'Fortnightly' },
    { value: '1m', label: 'Monthly' },
    { value: '1y', label: 'Yearly' }
  ];
  contact = {
    name: null,
    street_number: null,
    followup: null,
    route: null,
    notes: null,
    phone: null,
    email: null
  };

  addReminder() {

    const date = (new Date());
    this.followUp = true;
    this.followUpTime.date = new Date(date.getTime());
    this.followUpTime.date.setDate(this.followUpTime.date.getDate() + 7);

    this.followUpTime.timepicker = this.utils.timeStrFromDate(date);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

  }

  setEventType(val) {

    // Values for val
    // "Contact" === "c"
    // "Not Home" === 0
    // "Not Interested" === 1
    
    this.init = true;
    if (val === 'c') {
      this.data.home = 1;
    } else {
      this.data.home = 0;
      this.eventTypeValue = val;
    }
    
  }

  save() {

    if (!this.init) {
      
      this.close();

    } else {

      const response = {
        address: {},
        contact: this.contact,
        eventType: this.eventTypeValue,
        home: this.data.home,
        followup: null
      };


      if (this.followUp) {

        this.utils.parseDateTime(this.followUpTime);
        this.contact.followup = this.contact.followup || [];
        this.contact.followup.push(this.followUpTime);

      }

      this.data.address.street_number = this.contact.street_number || this.data.address.street_number;
      this.data.address.route = this.contact.route || this.data.address.route;

      response.address = this.data.address;

      this.dialogRef.close(response);

    }

  }

  close() {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-book-feed',
  templateUrl: './book-feed.component.html',
  styleUrls: ['./book-feed.component.css']
})
export class BookFeedComponent implements OnInit, OnDestroy {

  @ViewChild('feedView') feedView;

  constructor(
    private db: DbService,
    private router: Router,
    public dialog: MatDialog,
    private notify: NotificationService,
    private utils: TuilderUtilities
  ) { }

  entryBooks: Object[];
  isMenuOpen: Boolean = false;
  le;
  subs = [];
  books;
  isPgmBarOpen = false;
  program;
  programs: Array<Object> = [];
  programID = 0;
  isLeader;
  leaders = [];
  leader;
  entry = [];
  entryTxt = '';
  location = null;
  confirming = 0;
  locationTimeout = 0;
  groups;
  selectedGroup;
  time;
  money;
  justLogged: Boolean = false;

  today = moment().startOf('day').valueOf();

  logout() {
    this.db.q('magabooksApp:mbLogout').then(() => {
      this.router.navigateByUrl('login');
    });
  }

  contact() {

    const dialogData = {
      address: {},
      home: null
    };

    this.getAddress().then((address) => {
      dialogData.address = address;
    });

    const location = this.getLocation(this.justLogged ? 1 : 0);

    if (this.justLogged) {
      this.justLogged = false;
    }

    const dialogRef = this.dialog.open(CreateContactComponent, {
      width: '500px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {

      this.isMenuOpen = false;

      if (result) {

        location.then((locationVal) => {
          
          this.db.q('magabooksApp:createContact', {
            contact: result.contact,
            address: result.address,
            eventType: result.eventType,
            location: locationVal,
            le: this.db.le,
            home: result.home
          }).then(() => {
            if (result.contact.followup) {
              this.db.updateReminders();
            }
          });

        });

      }

      this.location = null;

    });

  }

  chooseLeaders(skip) {

    const promise = new Promise((resolve, reject) => {

      if (skip && (this.db.leader || this.db.leader === 0)) {

        resolve({
          leader: this.db.leader
        });

      } else {

        const dialogRef = this.dialog.open(ChooseLeaderComponent, {
          width: '300px',
          position: {
            top: '3%'
          },
          data: {}
        });

        dialogRef.afterClosed().subscribe(result => {

          this.isMenuOpen = false;

          if (result) {

            this.db.leader = result['leader'] || 0;

          }

          resolve(result);

        });
      }

    });

    return promise;

  }

  cancel() {
    this.entry = [];
    this.entryTxt = '';
    this.location = null;
  }

  showError(str: string) {
    const errorNotif2 = new Notification;
    errorNotif2.message = str;
    errorNotif2.type = 'error';
    this.notify.add(errorNotif2);
  }

  confirm() {

    this.chooseLeaders(1).then((response) => {

      const leader = response && response['leader'] || 0;

      this.leader = leader;

      const grouped = this.getGroupedEntries();

      const start = moment().tz(this.db.tz['utc']).startOf('day');
      const end = moment().tz(this.db.tz['utc']).endOf('day');

      this.locationTimeout = 0;

      this.db.leader = this.leader;

      this.justLogged = true;

      this.contact();

      this.db.q('magabooksApp:logEntry', {
        books: grouped,
        leader: this.leader,
        program: this.db.program['kp_programID'],
        dayStart: start.valueOf(),
        timeNow: moment().valueOf(),
        dayEnd: end.valueOf(),
        location: this.location,
        le: this.db.le['kp_canvasserID']
      }).then((data) => {

        this.location = null;

        if (data['entries']) {

          this.db.addEntries(data['entries']);

          this.groups = this.db.groupEntries();
          this.selectedGroup = this.groups[0];
          this.money = this.db.money;

          this.cancel();

        }

        this.confirming = 0;
      });

    });

  }

  logEntry() {

    this.confirming = 1;

    const location = this.getLocation();

    location.then((location) => {

      this.confirm();

    });

  }

  getAddress() {

    const promise = new Promise((resolve, reject) => {

      this.getLocation(1).then((location) => {

        if (location) {

          this.db.getJson(
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            location[0] +
            ',' +
            location[1] +
            '&key=' +
            this.db.mapsApiKey
          ).subscribe(data => {

            const components = data['results'][0].address_components;

            const address = {};

            for (const row of components) {
              address[row['types'][0]] = row['short_name'];
            };

            resolve(address);

          });

        }

      });

    });

    return promise;

  }

  getLocation(skip?) {

    const promise = new Promise((resolve, reject) => {

      if (skip && this.location) {

        resolve(this.location);

      } else {

        this.location = null;

        if (navigator.geolocation) {

          navigator.geolocation.getCurrentPosition((position) => { // on success

            if (position.coords.latitude) {

              this.location = [position.coords.latitude, position.coords.longitude];

              resolve(this.location);

            } else {

              resolve(false);

            }


          }, (PositionError) => { // on error

            resolve(false);

            this.showError(['', 'Access to Location Denied', 'Position Unavailable', 'Location Timeout'][PositionError.code]);

          }, { // Options
              enableHighAccuracy: true,
              timeout: 10000,
              maximumAge: 0
            });
        } else {
          this.showError('Your location is turned off or denied');
          resolve(false);
        }

      }

    });

    return promise;

  }

  addBook(code: String) {
    this.entry.push(code);
    this.generateEntryTxt();
  }

  getGroupedEntries() {

    let grouped: any = {}

    for (const code of this.entry) {
      grouped[code] = grouped[code] || {
        num: 0,
        code: code
      };
      grouped[code].num++;
    };

    grouped = [...Object.values(grouped)];
    grouped.sort((a: any, b: any) => {
      return a.num - b.num;
    }).reverse()

    return grouped;

  }

  generateEntryTxt() {

    const grouped = this.getGroupedEntries() as any

    const entries = [];
    for (const row of grouped) {
      entries.push(row['num'] + ' ' + row['code']);
    }

    this.entryBooks = [];

    for (const group of grouped) {
      const book = this.utils.find(this.books, 'code', group['code']);
      book.qty = group['num'];
      this.entryBooks.push(book);
    };

    this.entryBooks.reverse();

    this.entryTxt = entries.join(',  ');

  }

  getBookFeed() {
    
    this.db.q('magabooksApp:getProgramBookFeed', {
      program: this.programID,
      le: this.db.le['kp_canvasserID']
    }).then((data) => {

      this.db.money = data['money'];

      this.books = data['books'] || [];
      this.db.inventory = data['inventory'];
      if (data['entries']) {

        this.db.clearEntries();

        this.db.addEntries(data['entries']);

      }

    });
  }

  delete(row) {
    if (confirm('Remove entry ' + row.obj.name)) {
      const label = this.selectedGroup.label;
      this.utils.delete(this.selectedGroup.entries, row);
      this.db.removeEntries(row);
      this.db.q('magabooksApp:removeEntry', { entry: row });
      this.groups = this.db.groupEntries();
      this.selectedGroup = this.utils.find(this.groups, 'label', label) || this.groups[0] || null;
    }
  }

  changeProgram() {
    this.program = this.db.changeProgram(this.programID);
    this.getBookFeed();
  }

  ngOnInit() {

    localStorage.path = '/entry';

    this.feedView.value = 'entry';

    this.subs.push(this.db.myEntries.subscribe(entries => {

      this.groups = this.db.groupEntries();

      this.selectedGroup = this.groups[0];
      this.money = this.db.money;

    }));

    this.db.getLeDetails().then(() => {

      this.le = this.db.le;
      this.leaders = this.db.leaders;
      this.leader = this.db.leader;
      this.program = this.db.program;
      this.programs = this.db.programs;
      this.programID = this.program.kp_programID;
      this.isLeader = this.db.le.is_leader;
      this.getBookFeed();

    });

  }

  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

}
