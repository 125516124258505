import { Directive, ElementRef, Input, OnInit, OnChanges } from '@angular/core';
import { RelativeTimePipe } from './relative-time.pipe';

@Directive({
  selector: '[appFollowupColor]'
})
export class FollowupColorDirective implements OnInit, OnChanges {

  @Input('appFollowupColor') time: number;

  constructor(el: ElementRef, private timePipe: RelativeTimePipe) {

    this.el = el.nativeElement;
    this.timePipe = timePipe;

  }

  el;
  colors = { close: 'var(--theme-main)', overdue: '#fe2727' };

  ngOnChanges() {

    const now = (new Date()).getTime();
    const oneDay = 86400 * 1000;
    const time = this.time;
    const relativeStr = this.timePipe.transform(time);

    if (now > time) {
      this.setColor(this.colors.overdue);
    } else if ((time - now) < oneDay || relativeStr.match('Tomorrow')) {
      this.setColor(this.colors.close);
    }

  }

  ngOnInit() {



  }

  setColor(color) {
    this.el.style.backgroundColor = color;
  }

}
