import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moneySum'
})
export class MoneySumPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (!value) {
      return 0;
    }

    const val =
        (parseInt(value.notes) || 0) +
        (parseFloat(value.coins) || 0) +
        (parseInt(value.paypal) || 0);

      return val;

  }

}
