import { Injectable } from '@angular/core';
import { TuilderUtilities } from './tuilder-utilities.service';

export class Notification {
  message: string;
  type: string;
}

@Injectable()
export class NotificationService {

  constructor(private utils: TuilderUtilities) {  }
  
  notifications: Notification[] = [];

  numRequests: number = 0;
 
  add(notif: Notification) {

    this.notifications.push(notif);

    setTimeout(() => {
			this.clear(notif);
    }, 10000);
    
  }
 
  clear(notif?: Notification) {
    if(notif) {
      this.utils.delete(this.notifications, notif);
    } else this.notifications = [];
  }

}