import { DbService } from 'src/app/db.service';
import { BreakpointState, BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { TuilderUtilities } from '../tuilder-utilities.service';

@Component({
  selector: 'app-simple-event',
  templateUrl: './simple-event.component.html',
  styleUrls: ['./../main-frame/main-frame.component.css', './simple-event.component.css']
})
export class SimpleEventComponent implements OnInit {

  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);
  title: String;
  permissions = 'View';

  constructor(
    private breakpointObserver: BreakpointObserver,
    public db: DbService,
    private router: Router,
    private route: ActivatedRoute,
    private utils: TuilderUtilities,
  ) {}

  eventID = window.location.pathname.split('/')[2];

  logout() {
    this.db.q('magabooksApp:eventLogout');
    this.router.navigateByUrl('/SimpleEvent/' + this.eventID + '/login');
    this.db.eventLoaded = null;
  }

  ngOnInit() {
    
    this.db.le = {
      kf_personID: 0,
      kp_canvasserID: 0,
      is_leader: 1
    };

    this.db.manageSession = false;

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
          this.load();
        }
    });

    this.load();

  }

  load() {
    this.db.loadEvent(this.eventID).then((res) => {
      this.title = res.program.name;
      this.permissions = res.permissions || 'View';
    });
  }

}
