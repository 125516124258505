import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SimpleEventMapComponent } from './simple-event-map/simple-event-map.component';
import { SimpleEventHomeComponent } from './simple-event-home/simple-event-home.component';
import { SimpleEventContactsComponent } from './simple-event-contacts/simple-event-contacts.component';
import { SimpleEventLoginComponent } from './simple-event-login/simple-event-login.component';
import { SimpleEventComponent } from './simple-event/simple-event.component';
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { MyTableComponent } from './my-table/my-table.component';
import { LoginComponent } from './login/login.component';
import { MainFrameComponent } from './main-frame/main-frame.component';
import { MyContactsComponent } from './my-contacts/my-contacts.component';
import { ViewContactsComponent } from './view-contacts/view-contacts.component';
import { BookFeedComponent } from './book-feed/book-feed.component';
import { EntryListComponent } from './entry-list/entry-list.component';
import { LeaderFeedComponent } from './leader-feed/leader-feed.component';
import { MapComponent } from './map/map.component';
import { SettingsComponent } from './settings/settings.component';

const routes: Routes = [

  {
    path: 'SimpleEvent',
    component: SimpleEventComponent,
    children: [
      {
        path: ':eventID/home',
        component: SimpleEventHomeComponent
      },
      {
        path: ':eventID/contacts',
        component: SimpleEventContactsComponent
      },
      {
        path: ':eventID/contacts/:placeID/:contactID',
        component: ViewContactsComponent
      },
      {
        path: ':eventID/login',
        component: SimpleEventLoginComponent
      },
      {
        path: ':eventID/map',
        component: SimpleEventMapComponent
      }
    ]
  },

  {
    path: '',
    component: MainFrameComponent,
    children: [
      {
        path: 'stats',
        component: MyDashboardComponent
      },
      {
        path: '',
        redirectTo: '/stats',
        pathMatch: 'full'
      },
      {
        path: 'canvassers',
        component: MyTableComponent
      },
      {
        path: 'entry/feed',
        redirectTo: '/entry',
        pathMatch: 'full'
      },
      {
        path: 'entry',
        component: BookFeedComponent
      },
      {
        path: 'contacts',
        component: MyContactsComponent
      },
      {
        path: 'contacts/:placeID/:contactID',
        component: ViewContactsComponent
      },
      {
        path: 'contacts/:placeID',
        component: ViewContactsComponent
      },
      {
        path: 'list/:time',
        component: EntryListComponent
      },
      {
        path: 'leader',
        component: LeaderFeedComponent
      },
      {
        path: 'map',
        component: MapComponent
      },
      {
        path: 'settings',
        component: SettingsComponent
      }
    ]
  },

  {
    path: 'login',
    component: LoginComponent
  }

];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule { }
