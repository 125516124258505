import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { DbService } from '../db.service';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.css']
})

export class EntryComponent implements OnInit {

  title = 'Loading...';
  program;

  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);

  constructor(
    private breakpointObserver: BreakpointObserver,
    public db: DbService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  canvassers = [];
  groups = [];
  money = {};
  isLeader = 0;
  delete;

  logout() {
    this.db.q('magabooksApp:mbLogout').then(() => {
      this.router.navigateByUrl('login');
    });
  }

  ngOnInit() {

    this.db.getLeDetails().then(() => {
      this.title = this.db.le && this.db.le.name;
      this.program = this.db.program.name;
      this.isLeader = this.db.le.is_leader;
    });

    this.db.myEntries.subscribe(entries => {
      this.groups = this.db.groupEntries();
      this.money = this.db.money;
    });

  }

}
