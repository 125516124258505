import { Component, OnInit, ViewChild } from '@angular/core';
import { TuilderUtilities } from '../tuilder-utilities.service';
import { DbService } from '../db.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  @ViewChild('mobile') mobile;

  constructor(
    private utils: TuilderUtilities,
    private db: DbService
  ) { }

  personID;

  saveMobile() {

    this.db.q('magabooksApp:saveMobile', { mobile: this.mobile.value, id: this.db.le['kp_canvasserID'] });

    this.db.le['mobile'] = this.mobile.value;

  }

  ngOnInit() {

    this.db.getLeDetails().then(() => {
      this.mobile.value = this.db.le.mobile;
      this.personID = this.db.le.kf_personID;
    });

  }

}
