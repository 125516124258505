import { Component, OnInit, OnDestroy } from '@angular/core';
import { DbService } from '../db.service';
import { Router } from '@angular/router';
import { NotificationService } from '../notifications.service';
import { TuilderUtilities } from '../tuilder-utilities.service';
import { TimeAgoPipe } from '../time-ago.pipe';

@Component({
  selector: 'app-leader-feed',
  templateUrl: './leader-feed.component.html',
  styleUrls: ['./leader-feed.component.css']
})
export class LeaderFeedComponent implements OnInit, OnDestroy {

  constructor(
    private db: DbService,
    private router: Router,
    private timeAgo: TimeAgoPipe,
    private notify: NotificationService,
    private utils: TuilderUtilities
  ) { }

  feed = [];
  leaders = [];
  leader = 0;
  magabooks = 0;
  dropdowns = 0;
  wsConnection;
  windowTimeout;
  magabookSound = new Audio('https://magabooks.org.au/mb_sounds/magabook.mp3');
  dropdownSound = new Audio('https://magabooks.org.au/mb_sounds/dropdown.mp3');
  sort = 'priority';
  sortOptions = [
    {
      value: 'priority',
      label: 'Sort By Priorty'
    },
    {
      value: 'new',
      label: 'Newest First'
    },
  ];

  ngOnDestroy() {
    if (this.wsConnection) {
      this.wsConnection.close();
    }
    if (this.windowTimeout) {
      window.clearInterval(this.windowTimeout);
    }
  }

  updateLeader() {
    const startOfDay = (new Date).setHours(0, 0, 0, 0);
    this.db.q('magabooksApp:getLeaderFeed', { leader: this.leader, le: this.db.le.kp_canvasserID, start: startOfDay }).then((data) => {
      this.feed = data['feed'];
      this.sortFeed();
    });
  }

  updateSort() {
    this.sortFeed();
  }

  ngOnInit() {

    this.windowTimeout = window.setInterval(() => {

      for (const row of this.feed) {
        row.timeAgo = this.timeAgo.transform(row.time);
      }

    }, 3000);

    const startOfDay = (new Date).setHours(0, 0, 0, 0);

    this.leader = this.db.le.kf_personID;

    this.db.q('magabooksApp:getLeaderFeed', { leader: this.leader, le: this.db.le.kp_canvasserID, start: startOfDay }).then((data) => {
      this.feed = data['feed'];
      this.leaders = data['leaders'];
      this.leaders.unshift({ kf_personID: 0, name: 'All Leaders' });
      this.sortFeed();
    });

    this.db.q('magabooksApp:getWsPwd').then((data) => {

      const pwd = data['pwd'];

      this.wsConnection = new WebSocket('wss://magabooks:' + pwd + '@magabooks.org.au/ws/');

      this.wsConnection.onmessage = (e) => {

        const data = JSON.parse(e.data);

        if (data) {
          if (data.new || data.update) {

              const item = data['feed'][0];

              if (!this.leader || item.leader == this.leader) {

                if (data.new) {

                  const sound = item.type === 'Magabook' ? this.magabookSound : this.dropdownSound;

                  sound.currentTime = 0;
                  sound.play();

                  this.feed.unshift(item);
                  this.sortFeed();

                } else {

                  const destinationObj = this.utils.find(this.feed, 'id', item.id);
                  if (destinationObj) {
                    Object.assign(destinationObj, item);
                  }

                }

              }

          } else if (data.delete) {
            this.utils.deleteByVal(this.feed, 'id', data.delete);
            this.sortFeed();
          }
        }
      };

    });

  }

  complete(row) {
    row.completed = row.completed ? 0 : 1;
    this.db.q('magabooksApp:leaderCompleteItem', { id: row.id, value: row.completed });
    this.sortFeed();
  }

  countBooks() {
    this.magabooks = this.utils.filter(this.feed, 'type', 'Magabook').length;
    this.dropdowns = this.feed.length - this.magabooks;
  }

  sortFeed() {

    this.countBooks();

    if (this.sort === 'priority') {

      this.feed.sort(function (a, b) {
        return a.completed ? (a.time - b.time) : (a.time + b.time);
      }).reverse().sort(function (a, b) {
        return a.completed - b.completed;
      });

    } else if (this.sort === 'new') {

      this.feed.sort(function (a, b) {
        return a.time - b.time;
      }).reverse();

    }

  }

}
